import {connect} from "react-redux";

const CabinetLoading = ({children, loading}) => {

    return (<div>
        {
            loading &&
                <div className="flex justify-center items-center h-96">
                    <div className="w-12 h-12 border-2 border-t-8 border-sky-800 rounded-full animate-spin"></div>
                </div>
        }
        {!loading && children}
    </div>)
}

const mapStatToProps = (state) => {
    return {
        loading: state.cabinet.utils.loading
    }
}

export default connect(mapStatToProps)(CabinetLoading);