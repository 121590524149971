import { core } from "../..";
import { requestFormLoading } from "../../request/reducer/action";
import {
	loadingCabinet,
	loadingProfile,
	setUserProfile,
} from "../util.reducer";

export const getUserProfile = () => (dispatch) => {
	dispatch(loadingCabinet(true));
	dispatch(loadingProfile(true));
	dispatch(requestFormLoading(true));

	core
		.get("api/public/cabinet/user-profile")
		.then((r) => {
			dispatch(setUserProfile(r.data.profile));
		})
		.catch(() => {})
		.finally(() => {
			dispatch(loadingCabinet(false));
			dispatch(requestFormLoading(false));
			dispatch(loadingProfile(false));
		});
};

export const updateUserProfile = (values) => (dispatch) => {
	core
		.put("api/public/cabinet/user-profile/" + values.id, values)
		.then((r) => {
			dispatch(setUserProfile(r.data.profile));
		})
		.catch(() => {})
		.finally(() => dispatch(loadingCabinet(false)));
};
