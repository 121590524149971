import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";

const Pagination = ({
	current_page,
	last_page,
	links,
	total,
	to,
	from,
	serviceCall,
}) => {
	const dispatch = useDispatch();

	const handleNextPage = (page) => {
		dispatch(serviceCall(page));
	};

	const handlePreviousPage = (page) => {
		dispatch(serviceCall(page));
	};

	return (
		<div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
			<div className="flex flex-1 justify-between sm:hidden">
				<div
					onClick={handlePreviousPage}
					className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
				>
					Previous
				</div>
				<div
					onClick={handleNextPage}
					className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
				>
					Next
				</div>
			</div>
			<div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
				<div>
					<p className="text-sm text-gray-700">
						Showing <span className="font-medium">{from}</span> to{" "}
						<span className="font-medium">{to}</span> of{" "}
						<span className="font-medium">{total}</span> results
					</p>
				</div>
				<div>
					<nav
						className="isolate inline-flex -space-x-px rounded-md shadow-sm cursor-pointer"
						aria-label="Pagination"
					>
						{links?.map((link, key) => {
							if (key === 0) {
								return (
									<button
										key={key}
										disabled={current_page === from}
										onClick={() => handlePreviousPage()}
										className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-60 disabled:cursor-not-allowed"
									>
										<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
									</button>
								);
							} else if (key + 1 === links.length) {
								return (
									<button
										key={key}
										// eslint-disable-next-line eqeqeq
										disabled={last_page == current_page}
										onClick={() => handleNextPage()}
										className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-60 disabled:cursor-not-allowed"
									>
										<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
									</button>
								);
							}

							return (
								<button
									key={key}
									onClick={() => handleNextPage(link.label)}
									className={`
                                        ${
																					link.active
																						? "relative z-10 inline-flex items-center bg-sky-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
																						: "relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
																				} `}
								>
									{link.label}
								</button>
							);
						})}

						{/* Current: "z-10 bg-sky-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
						{/* <div className="z-10 bg-sky-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"> */}
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
