const initState = {
	loading: true,
	error: false,
	success: false,
	data: null,
};

export const extrasReducer = (state = initState, action) => {
	switch (action.type) {
		case "LOADING_EXTRAS":
			return { ...state, loading: action.payload };
		case "SET_EXTRAS_DATA":
			return { ...state, data: action.payload };
		case "SET_EXTRAS_ERROR":
			return { ...state, error: action.payload };
		case "SET_EXTRAS_SUCCESS":
			return { ...state, success: action.payload };
		default:
			return state;
	}
};
