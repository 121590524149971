import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getCurrent } from "../service/currentService";
import ResponsiveTableProxy from "../../components/ResponsiveTableProxy";
import CabinetEmptySpace from "../../components/CabinetEmptySpace";
import CabinetLoading from "../../components/CabinetLoading";
import CabinetPageLayout from "../../components/CabinetPageLayout";
import Pagination from "../../components/Pagination";

const Current = ({ getCurrent, current }) => {
	const {
		data,
		current_page,
		first_page_url,
		last_page_url,
		links,
		total,
		to,
		from,
		last_page,
	} = current;

	useEffect(() => {
		document.title = "Current | Delivery Network";

		getCurrent(1);
	}, [getCurrent]);

	return (
		<CabinetPageLayout>
			<CabinetLoading>
				<CabinetEmptySpace data={data}>
					<ResponsiveTableProxy data={data} />
					<Pagination
						{...{
							current_page,
							first_page_url,
							last_page_url,
							last_page,
							links,
							total,
							to,
							from,
							serviceCall: getCurrent,
						}}
					/>
				</CabinetEmptySpace>
			</CabinetLoading>
		</CabinetPageLayout>
	);
};

const mapStateToProps = (state) => {
	return {
		current: state.cabinet.current.orders,
	};
};

const mapDispatch = (dispatch) => {
	return {
		getCurrent: (page) => dispatch(getCurrent(page)),
	};
};

export default connect(mapStateToProps, mapDispatch)(Current);
