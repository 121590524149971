import React, { useState } from "react";
import { connect } from "react-redux";
import LoginForm from "./LoginForm";
import { motion } from "framer-motion";
import LoginChangePasswordForm from "./LoginChangePasswordForm";

const containerVariants = {
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { duration: 0.5 } },
};

const Login = ({ response }) => {
	const [username, setUsername] = useState(null);

	return (
		<motion.div
			variants={containerVariants}
			initial="hidden"
			animate="visible"
			className="flex py-7 sm:py-15 "
		>
			<div className="flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
				<div className="border-b border-gray-900/10 pb-12 pt-6 sm:pt-8 mb-12">
					<div className="overflow-hidden bg-white shadow sm:rounded-lg  pt-4 px-8 ">
						<div className="mx-auto w-full max-w-sm lg:w-96 pb-5">
							<div>
								<h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 text-center">
									{response?.message ? response.message : "Sign in to your account"}
								</h2>
							</div>
							{(!response?.status || response?.status ==='end') && <LoginForm {...{ setUsername }} />}
							{response?.status === "new_password_required" && (
								<LoginChangePasswordForm {...{ username }} />
							)}
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
};

const mapStateToProps = (state) => {
	return {
		response: state.cabinet.utils.response,
		user: state.cabinet.utils.authUser,
		loading: state.cabinet.utils.loading,
	};
};

export default connect(mapStateToProps, null)(Login);
