import {SET_LOADING_REGISTER_FORM, SET_REGISTRATION_INIT, SET_REGISTRATION_LOADING} from "./actions";

const initState = {
    init: {},
    loading: false,
    loadingForm: false,
}

export const registerReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_REGISTRATION_LOADING:
            return {...state, loading: action.payload};
        case SET_LOADING_REGISTER_FORM:
            return {...state, loadingForm: action.payload};
        case SET_REGISTRATION_INIT:
            return {...state, init: action.payload};
        default:
            return state;
    }
}