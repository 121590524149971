const ActionCard = ({ label, buttonLabel, profile, setOpen }) => {
	return (
		<div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
			<div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
				<div className="ml-4 mt-2">
					<h3 className="text-base font-semibold leading-6 text-gray-900">
						{label}
					</h3>
				</div>
				<div className="ml-4 mt-2 flex-shrink-0">
					<button
						onClick={() => setOpen(true)}
						type="button"
						className="relative inline-flex items-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
					>
						{buttonLabel}
					</button>
				</div>
			</div>

			<ul className="divide-y divide-gray-100">
				{profile?.addresses?.map((address) => (
					<li key={address.id} className="flex justify-between gap-x-6 py-5">
						<div className="flex min-w-0 gap-x-4">
							<div className="min-w-0 flex-auto">
								<p className="text-sm font-semibold leading-6 text-gray-900">
									{address.full_address}
								</p>
								<p className="mt-1 truncate text-xs leading-5 text-gray-500">
									{address.name}
								</p>
							</div>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export default ActionCard;
