import { connect } from "react-redux";
import { CheckIcon } from "@heroicons/react/20/solid";

const SuccessView = ({ showSuccess, children }) => {
	if (showSuccess)
		return (
			<div className="pb-12 pt-6 sm:pt-8 sm:px-48">
				<div className="bg-white shadow sm:rounded-lg py-4 px-0 sm:py-8 sm:px-16">
					<div className="flex justify-center items-center mt-12">
						<div className="bg-green-700/75 rounded-full p-6">
							<CheckIcon className={"h-16 text-white"} />
						</div>
					</div>
					<h1 className="text-2xl font-bold text-center mt-5">
						We've successfully received your delivery details.{" "}
					</h1>
					<p className="text-center mt-3">
						Thank you for your submission! Your order is now being processed. If
						you need to make any changes or have any questions, please don't
						hesitate to reach out to our customer service team. We appreciate
						your trust in us and look forward to delivering your order!
					</p>
				</div>
			</div>
		);
	else return children;
};

const mapStateToProps = (state) => {
	return {
		showSuccess: state.request.showSuccess,
	};
};

export default connect(mapStateToProps, null)(SuccessView);
