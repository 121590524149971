import { CABINET_PAYMENT_LISTING } from "./action";

const initState = {
	orders: {},
};

export const paymentReducer = (state = initState, action) => {
	switch (action.type) {
		case CABINET_PAYMENT_LISTING:
			return { ...state, orders: action.payload };
		default:
			return state;
	}
};
