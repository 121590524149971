import { connect } from "react-redux";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const ErrorView = ({ showError, children }) => {
	if (showError)
		return (
			<div className="pb-12 pt-6 sm:pt-8 sm:px-48">
				<div className="bg-white shadow sm:rounded-lg py-4 px-0 sm:py-8 sm:px-16">
					<div className="container mx-auto mt-10">
						<div className="flex justify-center items-center">
							<div className="bg-red-700/75 rounded-full p-6">
								<ExclamationTriangleIcon className={"h-16 text-white"} />
							</div>
						</div>
						<h1 className="text-2xl font-bold text-center mt-5">
							We can't receive enquirers at this moment
						</h1>
						<p className="text-center mt-3">
							We are very sorry, something happened on our end and we can't
							receive your request, please try again later
						</p>
						{/*<div className="mt-8 text-center">*/}
						{/*    <div onClick={() => navigate('/login')}*/}
						{/*         className="bg-sky-900 hover:bg-sky-500 text-white font-bold py-2 px-4 rounded">*/}
						{/*        Login*/}
						{/*    </div>*/}
						{/*</div>*/}
					</div>
				</div>
			</div>
		);
	else return children;
};

const mapStateToProps = (state) => {
	return {
		showError: state.request.showError,
	};
};

export default connect(mapStateToProps, null)(ErrorView);
