import { motion } from "framer-motion";

const containerVariants = {
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { duration: 0.5 } },
};

const LoadingForm = ({ children, loading }) => {
	return loading ? (
		<div className="loading-view">
			<div className="flex justify-center items-center h-96">
				<div className="w-12 h-12 border-2 border-t-8 border-sky-800 rounded-full animate-spin"></div>
			</div>
		</div>
	) : (
		<motion.div variants={containerVariants} initial="hidden" animate="visible">
			{children}
		</motion.div>
	);
};

export default LoadingForm;
