import moment from "moment";
import { Link } from "react-router-dom";

const ResponsiveTableProxy = ({ data }) => {
	return (
		<div className="-mx-4 mt-8 sm:-mx-0">
			<div className="overflow-hidden bg-white shadow sm:rounded-lg  pt-4 px-8 ">
				<table className="min-w-full divide-y divide-gray-300">
					<thead>
						<tr>
							<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0table-cell"
							>
								#
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
							>
								Collection Date
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
							>
								Collection Address
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
							>
								Title
							</th>
							<th
								scope="col"
								className="hidden w-24 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
							>
								Delivery Date
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
							>
								Delivery Address
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900table-cell"
							>
								Amount
							</th>
							<th
								scope="col"
								className="relative py-3.5 pl-3 pr-4 sm:pr-0 table-cell"
							>
								<span className="sr-only">Edit</span>
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{data.map((row, key) => (
							<tr key={key}>
								<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
									<Link to={`/tracking/${row.tracking_id}`}>
										<div className="hover:underline">{row.tracking_id}</div>
									</Link>
								</td>
								<td className="hidden whitespace-nowrap py-4 pl-4 pr-3 text-smtext-gray-900 sm:pl-0 sm:table-cell">
									{row.collection.route
										? moment(row.collection.route.date).format("D/M/Y")
										: "N/A"}
								</td>
								<td className="hidden whitespace-break-spaces px-3 py-4 text-sm text-gray-500 lg:table-cell">
									{`${row.collection.station.address_line_1 ?? ""} ${
										row.collection.station.address_line_2 ?? ""
									} ${row.collection.station.postcode ?? ""}`}
								</td>
								<td className="whitespace-break-spaces px-3 font-medium  py-4 text-sm text-gray-500 table-cell">
									{row.title}
								</td>
								<td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
									{row.delivery.route
										? moment(row.delivery.route.date).format("D/M/Y")
										: "N/A"}
								</td>
								<td className="hidden whitespace-break-spaces px-3 py-4 text-sm text-gray-500 lg:table-cell">
									{`${row.delivery.station.address_line_1 ?? ""} ${
										row.delivery.station.address_line_2 ?? ""
									} ${row.delivery.station.postcode ?? ""}`}
								</td>
								<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 table-cell">
									{row.main_payment.amount
										? `£${row.main_payment.amount}`
										: "-"}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ResponsiveTableProxy;
