import { useNavigate, useParams } from "react-router";
import StatusBar from "./StatusBar";
import { useDispatch, useSelector } from "react-redux";
import { getTrackingData } from "./service";
import { useEffect } from "react";

const TrackingBoard = () => {
	const { tokenId } = useParams();
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const tracking = useSelector((state) => state.tracking.info);

	useEffect(() => {
		if (tokenId) dispatch(getTrackingData(tokenId, navigate));
	}, [dispatch, navigate, tokenId]);

	if (!tracking) return null;

	return (
		<div className="mx-auto max-w-3xl">
			<div className="max-w-xl px-4">
				<p className="mt-2 text-4xl font-bold tracking-tight">
					{tracking.status.details.title}
				</p>
				<p className="mt-2 text-base text-gray-500">
					{tracking.status.details.description}
				</p>
			</div>
			<div className="mt-10 mx-w-xl">
				<p className={`text-2xl text-center font-medium text-gray-900`}>
					{tracking.order.title}
				</p>
			</div>
			<StatusBar {...{ status: tracking.status }} />
			{/* <Map {...{coordinates: tracking.coordinates}}/> */}
			<section
				aria-labelledby="order-heading"
				className="mt-10 pb-10 px-4 bg-white"
			>
				<div className="sm:mx-20">
					<dl className="grid grid-cols-2 gap-x-6 py-10 text-sm">
						<div>
							<dt className="font-medium text-xl text-gray-900">Collection</dt>
							<dd className="mt-2 text-gray-700 text-lg">
								<p>{tracking.collection?.address}</p>
								<p>{tracking.collection?.date}</p>
							</dd>
						</div>
						<div>
							<dt className="font-medium text-xl text-gray-900">Delivery</dt>
							<dd className="mt-2 text-gray-700 text-lg">
								<p>{tracking.delivery?.address}</p>
								<p>{tracking.delivery?.date}</p>
							</dd>
						</div>
					</dl>
				</div>
			</section>
		</div>
	);
};

export default TrackingBoard;
