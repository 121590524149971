const ConfirmationDestination = ({ formState }) => {
	return (
		<>
			<div className="px-4 pb-6 pt-16 sm:grid sm:grid-cols-3 sm:gap-4">
				<dt className="text-lg font-medium leading-6 text-gray-900">Date</dt>
				<dd className="mt-1 text-lg leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
					{formState.date}
				</dd>
			</div>

			<div className="px-4 pt-6 pb-16 sm:grid sm:grid-cols-3 sm:gap-4">
				<dt className="text-lg font-medium leading-6 text-gray-900">Address</dt>
				<dd className="mt-1 text-lg leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
					{formState.address}
				</dd>
			</div>
		</>
	);
};

export default ConfirmationDestination;
