import { useDispatch } from "react-redux";
import { useEffect } from "react";
import FormBanner from "./FormBanner";
import { useLocation, useParams } from "react-router";
import RequestFormV2 from "./RequestFormV2";
import LoadingView from "./LoadingView";
import ErrorView from "./ErrorView";
import SuccessView from "./SuccessView";
import { getRequestData } from "../services/requestService";

const Request = () => {
	const { fqdn } = useParams();
	const { search } = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		document.title = "Request a quote | Delivery service";

		let query = null;
		if (search) {
			const searchParams = new URLSearchParams(search);
			query = searchParams.get("q");
		}

		dispatch(getRequestData(query));
	}, [search, dispatch]);

	return (
		<div>
			{fqdn && <FormBanner />}
			<div className={"pt-10"}>
				<LoadingView>
					<ErrorView>
						<SuccessView>
							<RequestFormV2 />
						</SuccessView>
					</ErrorView>
				</LoadingView>
			</div>
		</div>
	);
};

export default Request;
