import React from "react";
import { useNavigate } from "react-router";

const TrackingSection = () => {
	const navigate = useNavigate();
	const [formValues, setFormValues] = React.useState({
		trackingId: "",
	});

	const onChange = (e) => {
		console.log({
			e,
			name: e.target.name,
			value: e.target.value,
		});
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = (e) => {
		navigate(formValues.trackingId);
	};

	return (
		<section className="relative isolate overflow-hidden py-16 sm:py-24 lg:py-32">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
					<div className="max-w-xl lg:max-w-lg">
						<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
							Track your parcel
						</h2>
					</div>
					<div className="max-w-xl lg:max-w-lg">
						<form className="w-full max-w-md lg:col-span-5 lg:pt-2">
							<div className="flex gap-x-4">
								<input
									id="trackingId"
									name="trackingId"
									type="trackingId"
									onChange={onChange}
									required
									className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
									placeholder="Enter your tracking id"
								/>
								<button
									type="button"
									onClick={onSubmit}
									className="flex-none rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
								>
									Submit
								</button>
							</div>
							<p className="mt-4 text-sm leading-6 text-gray-900">
								We care about your data. Read our{" "}
								<span
									onClick={() => navigate("/policy")}
									className="cursor-pointer font-semibold text-sky-600 hover:text-sky-500"
								>
									privacy&nbsp;policy
								</span>
								.
							</p>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default TrackingSection;
