import {combineReducers} from "redux";
import {currentReducer} from "./cabinet/current/reducer/currentReducer";
import {pastReducer} from "./cabinet/past/reducer/pastReducer";
import {paymentReducer} from "./cabinet/payments/reducer/paymentReducer";
import {utilReducer} from "./cabinet/util.reducer";
import {orderReducer} from "./cabinet/order/reducer/order.reducer";
// import {confirmationReducer} from "./confirmation/confirmation.reducer";

const cabinetReducer = combineReducers({
    current: currentReducer,
    past: pastReducer,
    payments: paymentReducer,
    utils: utilReducer,
    orders: orderReducer,
});

export default cabinetReducer
