import {
	SET_REQUEST_FORM_DATA,
	SET_REQUEST_FORM_LOADING,
	SET_REQUEST_FORM_SEARCH_ADDRESS,
	SET_REQUEST_FORM_SHOW_ERROR,
	SET_REQUEST_FORM_SHOW_SUCCESS,
	SET_REQUEST_PRICE_TIER_LOADING,
	SET_REQUEST_PAGE_LOADING,
} from "./action";

const initState = {
	autocompletePlaces: null,
	autocompleteLoading: false,
	order: {},
	disableForm: false,
	formStage: "initial",
	formSearchAddress: [],
	loading: true,
	loadingPage: true,
	showError: false,
	showSuccess: false,
	loadingPriceTier: true,
};

export const requestReducer = (state = initState, action) => {
	switch (action.type) {
		case SET_REQUEST_FORM_DATA:
			return { ...state, formData: action.payload };
		case SET_REQUEST_FORM_SEARCH_ADDRESS:
			return { ...state, formSearchAddress: action.payload };
		case SET_REQUEST_FORM_LOADING:
			return { ...state, loading: action.payload };
		case SET_REQUEST_PAGE_LOADING:
			return { ...state, loadingPage: action.payload };
		case SET_REQUEST_FORM_SHOW_ERROR:
			return { ...state, showError: action.payload };
		case SET_REQUEST_FORM_SHOW_SUCCESS:
			return { ...state, showSuccess: action.payload };
		case SET_REQUEST_PRICE_TIER_LOADING:
			return { ...state, loadingPriceTier: action.payload };
		default:
			return state;
	}
};
