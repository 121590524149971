import { useState } from "react";
import ActionCard from "../ActionCard";
import NewAddressModal from "../NewAddressModal";
import { PlusIcon } from "@heroicons/react/24/outline";

const AddressActionPanel = ({ profile }) => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<NewAddressModal {...{ open, setOpen }} />
			<ActionCard
				{...{
					label: "Addresses",
					buttonLabel: (
						<>
							<PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
							Add Address
						</>
					),
					profile,
					setOpen,
				}}
			/>
		</>
	);
};

export default AddressActionPanel;
