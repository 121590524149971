import { useSelector } from "react-redux";
import AnimatedInputProxy from "../../../../helpers/forms/AnimatedInputProxy";
import React, { useEffect, useState } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import _ from "lodash";

const ItemsFormStageV2 = ({ setFormValues, goNext, formValues, goBack, itemKey }) => {
	const itemTypes = useSelector((state) => state.request.formData?.itemTypes);
	const itemsS = useSelector((state) => state.request.formData?.items);

	const [items, setItems] = useState(itemsS);
	const [activeType, setActiveType] = useState(null);

	const [added, setAdded] = useState([]);

	useEffect(() => {
		setItems(itemsS);
	}, [itemsS]);

	useEffect(() => {
		if(!_.isEmpty(formValues.items)) {
			setAdded(formValues.items)
		}
	}, [formValues.items])

	const handleChangeCategory = (type) => {
		if (type.id === activeType?.id) {
			setItems(itemsS);
			setActiveType(null);
		} else {
			const newItems = itemsS.filter((i) => i.type === type.id);
			setActiveType(type);
			setItems(newItems);
		}
	};

	const handleSearch = (e) => {
		const newItems = items.filter((i) => i.title.includes(e.target.value));

		if (!e.target.value) {
			if (activeType) {
				handleChangeCategory(activeType);
			} else {
				setItems(itemsS);
			}
		} else {
			setItems(newItems);
		}
	};

	const handleAddItem = (item) => {
		handleFormValues(item);

		setAdded([...added, item]);
	};

	const handleFormValues = (values) => {
		const { items } = formValues;

		const newFormValues = {
			...formValues,
			items: [...(items ?? []), values],
		};

		setFormValues(newFormValues);
	};

	const handleRemoveItem = (item) => {
		const indexToRemove = added.findIndex((a) => a.id === item.id);

		if (indexToRemove !== -1) {
			const newAdded = [...added];
			newAdded.splice(indexToRemove, 1);
			setAdded(newAdded);
		}
	};

	const handleGoNext = () => {
		// const errors = handleStageValidation();
		// if (!errors.address && !errors.floor) {
		goNext();
		// }
	};

	return (
		<div className="border-t border-gray-900/10 pb-4">
			<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
				<div className="col-span-full row-span-1">
					<p className="text-lg w-full text-center mb-6"> Select a category</p>
					<ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
						{itemTypes?.map((type) => (
							<li
								key={type.id}
								onClick={() => handleChangeCategory(type)}
								className="relative"
							>
								<div
									className={` ${
										activeType?.id === type.id
											? "bg-sky-800 text-white"
											: "bg-gray-100 text-gray-900"
									} group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg  hover:bg-sky-600 hover:text-white focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100`}
								>
									<img
										src={type.source}
										alt=""
										className="pointer-events-none object-cover group-hover:opacity-75"
									/>
									<button
										type="button"
										className="absolute inset-0 focus:outline-none flext items-center "
									></button>
									<p className="pointer-events-none mx-4 my-2 block truncate text-sm font-medium">
										{type.title}
									</p>
								</div>
							</li>
						))}
					</ul>
				</div>
				<div className="col-span-full row-span-1">
					<div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
						<div className="px-4 py-5 sm:p-6">
							{added?.map((item, key) => {
								return (
									<span
										key={key}
										className="inline-flex items-center mx-4 mt-4 gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-lg font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
									>
										{item.title}

										<TrashIcon
											className="w-4 h-4 hover:text-sky-500 cursor-pointer"
											onClick={() => handleRemoveItem(item)}
										/>
									</span>
								);
							})}
						</div>
					</div>
				</div>
				<div className="col-span-full row-span-1">
					<p className="text-lg w-full text-center mb-6"> Select a Item</p>
					<AnimatedInputProxy
						{...{label: "Search Item", onChange: handleSearch}}
					/>
					<ul className="divide-y divide-gray-100 h-96 overflow-auto">
						{items?.map((item) => (
							<li
								key={item.id}
								className="flex items-center justify-between gap-x-6 py-5 px-4 sm:px-8 hover:bg-sky-50"
							>
								<div className="min-w-0">
									<div className="flex items-start gap-x-3">
										<p className="text-sm font-semibold leading-6 text-gray-900">
											{item.title}
										</p>
									</div>
								</div>
								<div className="flex flex-none items-center gap-x-4">
									<button
										onClick={() => handleAddItem(item)}
										className="hidden rounded-md bg-sky-800 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-sky-600 sm:block"
									>
										Add
									</button>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className="flex flex-col sm:flex-row justify-center space-y-4 my-4 sm:space-y-0 sm:space-x-4">
				{itemKey !== 0 && <button
					onClick={goBack()}
					type="button"
					className="w-full sm:w-auto px-6 py-3 bg-gray-200 text-gray-700 text-sm font-semibold rounded-md shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
				>
					Back
				</button>}
				<button
					onClick={handleGoNext}
					type="button"
					className="w-full sm:w-auto px-6 py-3 bg-sky-600 text-white text-sm font-semibold rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
				>
					Next
				</button>
			</div>
		</div>
	);
};

export default ItemsFormStageV2;
