import { Transition } from "@headlessui/react";
import { useState } from "react";

const AccordionItem = ({ item, itemKey, formValues, setFormValues, goNext, goBack }) => {
	const [subTitle, setSubTitle] = useState();
	const Component = item.component;

	const handleTitle = () => {
		if (item.formKey === "collection" || item.formKey === "delivery") {
			setSubTitle(formValues[item.formKey]?.address);
		}
	};

	const handleGoNext = () => {
		handleTitle();
		goNext();
	};

	const handleGoBack= () => {
		handleTitle();
		goBack();
	};

	return (
		<li key={item.id}>
			<div className="text-xl inline-flex items-center w-full px-4 py-4 sm:px-6">
				<div className="flex items-center">
					{item.icon}
					{item.title}
				</div>
				<div className="ml-4 text-base text-gray-500 align-bottom">
					{subTitle}
				</div>
			</div>
			<Transition
				show={item.status ?? false}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<div className="px-8">
					<Component
						{...{
							itemKey: itemKey,
							formValues,
							setFormValues,
							goNext: handleGoNext,
							goBack: handleGoBack,

							formKey: item.formKey,
						}}
					/>
				</div>
			</Transition>
		</li>
	);
};

export default AccordionItem;
