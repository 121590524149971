import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { isLoggedIn } from "../../../auth/login/service/loginService";
import { connect, useSelector } from "react-redux";

const tabsDefinition = [
	{ name: "Current", href: "/cabinet", current: false },
	{ name: "Past", href: "/cabinet/past", current: false },
	{ name: "Payments", href: "/cabinet/payments", current: false },
	{ name: "Profile", href: "/cabinet/profile", current: false },
];

const CabinetPageLayout = ({ children, isLogged }) => {
	// const [currentTab, setCurrentTab] = useState("/cabinet");
	const [tabs, setTabs] = useState(tabsDefinition);
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const user = useSelector((state) => state.cabinet.utils.user);

	useEffect(() => {
		setTabs(
			tabsDefinition.map((tab) => {
				if (tab.href === pathname) {
					return {
						...tab,
						current: true,
					};
				} else {
					return tab;
				}
			})
		);
	}, [setTabs, pathname]);

	return (
		<div className="bg-white rounded-sm py-10 px-8">
			<div className="mt-10 sm:flex sm:items-start sm:justify-between mb-8">
				<h1 className="max-w-xl px-2 text-4xl font-bold tracking-tight text-gray-800 sm:text-2xl overflow-hidden whitespace-nowrap text-overflow-ellipsis">
					Hi {user.full_name ?? user.email}
				</h1>
				<div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center mr-12">
					<button
						type="button"
						onClick={() => navigate("/cabinet/request")}
						className="ml-3 inline-flex items-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
					>
						<PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
						New Request
					</button>
				</div>
			</div>
			<div className="sm:hidden">
				{/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
				{/* <select
					id="tabs"
					name="tabs"
					className="block w-full rounded-md border-gray-300 focus:border-sky-500 focus:ring-sky-500"
					defaultValue={tabs.find((tab) => tab.current).name}
				>
					{tabs.map((tab) => (
						<option key={tab.name}>{tab.name}</option>
					))}
				</select> */}
			</div>
			<div className="hidden sm:block">
				<nav
					className="isolate flex divide-x divide-gray-200 rounded-lg shadow mt-4"
					aria-label="Tabs"
				>
					{tabs.map((tab, tabIdx) => (
						<div
							key={tab.name}
							onClick={() => navigate(tab.href)}
							className={`group relative min-w-0 flex-1 overflow-hidden cursor-pointer
							py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10
							${
								tab.current
									? "text-gray-900 bg-gray-100"
									: "text-gray-500 hover:text-gray-700 bg-white"
							}
							 ${tabIdx === 0 ? "rounded-l-lg" : ""}
							 ${tabIdx === tabs.length - 1 ? "rounded-r-lg" : ""}
							`}
							// className={
							// 	classNames(
							//
							//
							// 		,
							// 	,
							// 	,

							// )}
							aria-current={tab.current ? "page" : undefined}
						>
							<span>{tab.name}</span>
							{/*<span
								aria-hidden="true"
								className={classNames(
									tab.current ? "bg-sky-500" : "bg-transparent",
									"absolute inset-x-0 bottom-0 h-0.5"
								)}
							/> */}
						</div>
					))}
				</nav>
			</div>
			{children}
		</div>
		// <div className="bg-white rounded-sm px-8 pt-4">
		// 	<div className="relative border-b border-gray-200 pb-5 sm:pb-0">
		// 		<div className="md:flex md:items-center md:justify-between">
		// 			<div className="mt-3 flex md:absolute md:right-0 md:top-3 md:mt-0">

		// 			</div>
		// 		</div>
		// 		<div className="mt-4">
		// 			<div className="sm:hidden">
		// 				<label htmlFor="current-tab" className="sr-only">
		// 					Select a tab
		// 				</label>
		// 				<select
		// 					id="current-tab"
		// 					name="current-tab"
		// 					className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600"
		// 					defaultValue={currentTab.name}
		// 				>
		// 					{tabs.map((tab) => (
		// 						<option key={tab.name}>{tab.name}</option>
		// 					))}
		// 				</select>
		// 			</div>
		// 			<div className="hidden sm:block">
		// 				<nav className="-mb-px flex space-x-8">
		// 					{tabs.map((tab) => (
		// 						<div
		// 							key={tab.name}
		// 							onClick={() => navigate(tab.href)}
		// 							className={classNames(
		// 								tab.current
		// 									? "border-sky-500 text-sky-600"
		// 									: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
		// 								"whitespace-nowrap border-b-2 px-1 pb-4 text-lg font-medium"
		// 							)}
		// 							aria-current={tab.current ? "page" : undefined}
		// 						>
		// 							{tab.name}
		// 						</div>
		// 					))}
		// 				</nav>
		// 			</div>
		// 		</div>
		// 	</div>
		//
		// </div>
	);
};

const mapStateToProps = (state) => {
	return {
		current: state.cabinet.current.orders,
	};
};

const mapDispatch = (dispatch) => {
	return {
		isLoggedIn: () => dispatch(isLoggedIn()),
	};
};

export default connect(mapStateToProps, mapDispatch)(CabinetPageLayout);
