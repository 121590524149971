import { PlusIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router";

const CabinetEmptySpace = ({ children, data }) => {
	const navigate = useNavigate();
	if (data?.length > 0) return children;

	return (
		<div className={"flex justify-center"}>
			<div className="sm:w-3/6 overflow-hidden bg-white sm:rounded-lg pt-4 px-8 mt-24">
				<div className="text-center">
					<svg
						className="mx-auto h-12 w-12 text-gray-400 mt-12"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						aria-hidden="true"
					>
						<path
							vectorEffect="non-scaling-stroke"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
						/>
					</svg>
					<h3 className="mt-2 text-sm font-semibold text-gray-900">
						Sorry, we couldn't find any orders
					</h3>
					<p className="mt-1 text-sm text-gray-500">
						Get started by requesting new order.
					</p>
					<div className="mt-6 mb-6">
						<button
							onClick={() => navigate("/request")}
							type="button"
							className="inline-flex items-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
						>
							<PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
							New Request
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CabinetEmptySpace;
