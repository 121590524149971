import { core } from "../../..";
import { loadingCabinet } from "../../util.reducer";
import { setPaymentListing } from "../reducer/action";

export const getPayments = (page) => (dispatch) => {
	dispatch(loadingCabinet(true));

	core
		.get(`api/public/cabinet/payments?page=${page}`)
		.then(({ data }) => {
			dispatch(setPaymentListing(data));
			dispatch(loadingCabinet(false));
		})
		.catch((e) => {
			dispatch(loadingCabinet(false));
		});
};
