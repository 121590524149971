import React from "react";
import { Routes, Route } from "react-router-dom";
import Current from "./current/page";
import Payments from "./payments/page";
import Past from "./past/page";
import Request from "../request/page"; // Adjust the import path if necessary
import ViewOrder from "./order/page/ViewOrder";
import AuthGuard from "../auth/AuthGuard";
import Profile from "./profile";

const CabinetRoutes = () => {
	return (
		<AuthGuard>
			<Routes>
				<Route path="/" element={<Current />} />
				<Route path="/current" element={<Current />} />
				<Route path="/past" element={<Past />} />
				<Route path="/payments" element={<Payments />} />
				<Route path="/request" element={<Request />} />
				<Route path="/profile" element={<Profile />} />
				<Route path="/view/:orderId" element={<ViewOrder />} />
			</Routes>
		</AuthGuard>
	);
};

export default CabinetRoutes;
