import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { passwordChange } from "./service/loginService";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { connect } from "react-redux";
import { useNavigate } from "react-router";

const buttonVariants = {
	hover: { scale: 1.05 },
};

const getErrNo = (validation) => {
	let totalErrNum = 0;

	Object.keys(validation).forEach((key) => {
		if (!validation[key]) totalErrNum += 1;
	});

	return totalErrNum;
};

const ValidationMessageComponent = ({ validation }) => {
	let totalErrNum = getErrNo(validation);

	if (!totalErrNum) return null;

	return (
		<div className="rounded-md bg-red-50 p-4">
			<div className="flex">
				<div className="flex-shrink-0">
					<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
				</div>
				<div className="ml-3">
					<h3 className="text-sm font-medium text-red-800">
						There were {totalErrNum} rules{totalErrNum > 2 ? "s" : ""} you need
						to follow
					</h3>
					<div className="mt-2 text-sm text-red-700">
						<ul className="list-disc space-y-1 pl-5">
							{!validation.hasNumber && (
								<li>Must contain at least one number</li>
							)}
							{!validation.hasLowercase && (
								<li>Must contain at least one lowercase letter</li>
							)}
							{!validation.hasUppercase && (
								<li>Must contain at least one uppercase letter</li>
							)}
							{/* {!validation.hasSpecialChar && (
								<li>Must contain at least one special character</li>
							)} */}
							{!validation.hasValidLength && (
								<li>Must be at least 8 characters long</li>
							)}
							{!validation.isMatching && <li>Passwords must match</li>}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

const checkPasswordRules = (password, confirmPassword) => {
	const rules = {
		hasNumber: /\d/.test(password),
		hasLowercase: /[a-z]/.test(password),
		hasUppercase: /[A-Z]/.test(password),
		// hasSpecialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password),
		hasValidLength: password.length >= 8,
		isMatching: password === confirmPassword,
	};

	return rules;
};

const LoginChangePasswordForm = ({
	session,
	loading,
	passwordChange,
	username,
}) => {
	const [validation, setValidation] = useState({});
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		const rules = checkPasswordRules(password, confirmPassword);
		setValidation(rules);
	}, [password, confirmPassword]);

	const onSubmit = (e) => {
		e.preventDefault();
		passwordChange({ password, session, username }, navigate);
	};

	return (
		<div className="mt-6">
			<form action="#" onSubmit={onSubmit} method="POST" className="space-y-6">
				<div className="space-y-1">
					<label
						htmlFor="password"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Password
					</label>
					<div className="mt-2">
						<input
							id="password"
							name="password"
							type="password"
							onChange={(e) => setPassword(e.target.value)}
							autoComplete="current-password"
							required
							className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
						/>
					</div>
				</div>

				<div className="space-y-1">
					<label
						htmlFor="confirm_password"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Confirm Password
					</label>
					<div className="mt-2">
						<input
							id="confirm_password"
							name="confirm_password"
							type="password"
							onChange={(e) => setConfirmPassword(e.target.value)}
							autoComplete="current-password"
							required
							className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
						/>
					</div>
				</div>

				<ValidationMessageComponent {...{ validation }} />

				<div>
					<motion.button
						variants={buttonVariants}
						disabled={getErrNo(validation) > 0}
						whileHover="hover"
						type="submit"
						className="flex w-full justify-center rounded-md bg-sky-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
					>
						Change Password
					</motion.button>
				</div>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		session: state.cabinet.utils.response.session,
		loading: state.cabinet.utils.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	passwordChange: (data, navigate) => dispatch(passwordChange(data, navigate)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginChangePasswordForm);
