import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useSelector } from "react-redux";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const ConfirmationOptions = ({ handleChange, validationErrors }) => {
	const { details, options } = useSelector((state) => state.confirmation.data);

	const [selected, setSelected] = useState(null);
	const requiredIdsArr = details.require_description_option_ids?.split(",");

	const onChange = (e) => {
		handleChange({ target: { name: "option", value: e.id } });
		setSelected(e);
	};

	return (
		<RadioGroup value={selected} onChange={onChange}>
			<RadioGroup.Label className={"p-4 text-lg font-semibold"}>
				Select from options below
			</RadioGroup.Label>
			<div className="-space-y-px rounded-md bg-white">
				{options.map((option, optionIdx) => {
					// eslint-disable-next-line eqeqeq
					const isRequired = requiredIdsArr?.find((r) => r == option?.id);
					const isSelected = option.id === selected?.id;

					const radioButtonColor = {
						"--tw-ring-color": option.color,
					};

					return (
						<RadioGroup.Option
							key={option.title}
							value={option}
							style={radioButtonColor}
							className={({ checked }) =>
								classNames(
									optionIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
									optionIdx === options.length - 1
										? "rounded-bl-md rounded-br-md"
										: "",
									checked ? "z-10 border-sky-200 bg-sky-50" : "border-gray-200",
									"relative flex cursor-pointer border p-4 focus:outline-none"
								)
							}
						>
							{({ active, checked }) => (
								<>
									<span
										style={radioButtonColor}
										className={classNames(
											checked
												? "bg-sky-600 border-transparent"
												: "bg-white border-gray-300",
											active ? "ring-2 ring-offset-2 ring-sky-600" : "",
											"mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
										)}
										aria-hidden="true"
									>
										<span className="rounded-full bg-white w-1.5 h-1.5" />
									</span>
									<span className="ml-3 flex flex-col flex-grow">
										<RadioGroup.Label
											as="span"
											className={classNames(
												checked ? "text-sky-900" : "text-gray-900",
												"block text-sm font-medium"
											)}
										>
											{option.title}
										</RadioGroup.Label>
										{isRequired && isSelected && (
											<RadioGroup.Description
												as="span"
												className={classNames(
													checked ? "text-sky-700" : "text-gray-500",
													"block text-sm"
												)}
											>
												<div className="rounded-lg bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-sky-600">
													<textarea
														rows={2}
														name="client_notes"
														id="client_notes"
														onChange={handleChange}
														className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
														placeholder="Provide more details"
														defaultValue={""}
													/>
												</div>
												{validationErrors.requiredMissing && (
													<div className="text-red-500">Required field</div>
												)}
											</RadioGroup.Description>
										)}
									</span>
								</>
							)}
						</RadioGroup.Option>
					);
				})}
			</div>
		</RadioGroup>
	);
};

export default ConfirmationOptions;
