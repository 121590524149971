import HeroSection from "./HeroSection/HeroSection";
import LongFeatureSection from "./LongFeatureSection";
// import CtaSimpleSection from "./CtaSimpleSection";

const Home = () => {
	return (
		<main>
			<HeroSection />
			{/* <TrackingSection /> */}
			<LongFeatureSection />
			{/* <FeatureSection /> */}
			{/* <CtaSimpleSection /> */}
		</main>
	);
};

export default Home;
