import {SET_CABINET_PAST_LISTING} from "./action";

const initState = {
    orders: [],
}

export const pastReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_CABINET_PAST_LISTING:
            return {...state, orders: action.payload};
        default:
            return state;
    }
}