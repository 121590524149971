const initState = {
    token: {},
    options: [],
    status: [],
    loading: false,
}

export const verificationReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_FROM_TOKEN':
            return {...state, token: action.payload};
        case 'SET_VERIFICATION_OPTIONS':
            return {...state, options: action.payload};
        case 'SET_VERIFICATION_STATUS':
            return {...state, status: action.payload};
        case 'LOADING_VERIFICATION':
            return {...state, loading: action.payload};
        default:
            return state;
    }
}