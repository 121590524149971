import { connect } from "react-redux";
import { useEffect } from "react";
import { updateConfirmation, getConfirmation } from "./confirmation.service";
import { useParams } from "react-router";
import ConfirmationSuccess from "./ConfirmationSuccess";
import ConfirmationError from "./ConfirmationError";
import ConfirmationLoading from "./ConfirmationLoading";
import ConfirmationForm from "./ConfirmationForm";
import ConfirmationLayout from "./ConfirmationLayout";
import ConfirmationFormLayout from "./ConfirmationForm/ConfirmationFormLayout";

const Confirmation = ({ getConfirmation }) => {
	const { tokenId } = useParams();

	useEffect(() => {
		getConfirmation({ tokenId });
	}, [getConfirmation, tokenId]);

	return (
		<ConfirmationLayout>
			<ConfirmationFormLayout>
				<ConfirmationError>
					<ConfirmationSuccess>
						<ConfirmationLoading>
							<div className="mx-auto max-w-xl lg:max-w-4xl sm:">
								<h2 className="text-3xl font-bold tracking-tight text-gray-900">
									Confirmation Required
								</h2>
								<p className="mt-2 text-lg leading-8 text-gray-600">
									We just want to make sure we've got everything right. Please
									confirm your details below.
								</p>
							</div>
							<ConfirmationForm />
						</ConfirmationLoading>
					</ConfirmationSuccess>
				</ConfirmationError>
			</ConfirmationFormLayout>
		</ConfirmationLayout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.confirmation.loading,
		data: state.confirmation.data,
		error: state.confirmation.error,
		success: state.confirmation.success,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateConfirmation: (values) => dispatch(updateConfirmation(values)),
		getConfirmation: (token) => dispatch(getConfirmation(token)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
