import moment from "moment";
import { useSelector } from "react-redux";

const PaymentsTable = ({ data }) => {
	const state = useSelector((state) => state);
	console.log({ state });
	return (
		<div className="-mx-4 mt-8 sm:-mx-0">
			<div className="overflow-hidden bg-white shadow sm:rounded-lg  pt-4 px-8 ">
				<table className="min-w-full divide-y divide-gray-300">
					<thead>
						<tr>
							<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0table-cell"
							>
								#
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
							>
								Amount
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
							>
								Status
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
							>
								Due Date
							</th>
							<th
								scope="col"
								className="hidden w-24 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
							>
								Tracking Id
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{data?.map((row, key) => (
							<tr key={key}>
								<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
									{row.tracking_id}
								</td>
								<td className="hidden whitespace-nowrap py-4 pl-4 pr-3 text-smtext-gray-900 sm:pl-0 sm:table-cell">
									{row.main_payment.amount
										? `£${row.main_payment.amount}`
										: "-"}
								</td>
								<td className="hidden whitespace-break-spaces px-3 py-4 text-sm text-gray-500 lg:table-cell">
									{row.main_payment.status
										? `£${row.main_payment.status}`
										: "-"}
								</td>
								<td className="whitespace-break-spaces px-3 font-medium  py-4 text-sm text-gray-500 table-cell">
									{row.delivery.route
										? moment(row.delivery.route.date).format("D/M/Y")
										: "N/A"}
								</td>
								<td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
									{row.tracking_id}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default PaymentsTable;
