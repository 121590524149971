import { connect } from "react-redux";

const LoadingView = ({ loading, children, loadingAuth }) => {
	if (loading || loadingAuth)
		return (
			<div className="loading-view">
				<div className="flex justify-center items-center h-96">
					<div className="w-12 h-12 border-2 border-t-8 border-sky-800 rounded-full animate-spin"></div>
				</div>
			</div>
		);
	else return children;
};

const mapStateToProps = (state) => {

	return {
		loading: state.request.loadingPage,
		loadingAuth: state.cabinet.utils.loadingAuth,
	};
};

export default connect(mapStateToProps, null)(LoadingView);
