import { core } from "..";

export const getExtras = (values) => (dispatch) => {
	dispatch({ type: "LOADING_EXTRAS", payload: true });
	console.log(values.tokenId.length);
	if (values.tokenId.length !== 9) {
		dispatch({ type: "SET_EXTRAS_ERROR", payload: true });
	} else
		core
			.get("/api/public/extras", { params: values })
			.then((r) => {
				if (r.data.extras.status === "success") {
					dispatch({ type: "SET_EXTRAS_SUCCESS", payload: true });
				} else {
					dispatch({ type: "SET_EXTRAS_DATA", payload: r.data });
				}
			})
			.catch(() => {
				dispatch({ type: "SET_EXTRAS_ERROR", payload: true });
			})
			.finally(() => {
				dispatch({ type: "LOADING_EXTRAS", payload: false });
			});
};
