import { LockClosedIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

const FormBanner = () => {
	const [hidden, setHidden] = useState(
		sessionStorage.getItem("requestFormBanner") ?? false
	);

	const handleHidden = (flag) => {
		setHidden(flag);
		sessionStorage.setItem("requestFormBanner", flag);
	};

	if (hidden) return null;

	return (
		<div className="flex items-center gap-x-6 bg-white shadow sm:rounded-lg text-black px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
			<LockClosedIcon className=" w-4 h-4" />
			<p className="text-sm leading-6 ">
				<strong className="font-semibold">DefaultVan.co.uk</strong>
				<svg
					viewBox="0 0 2 2"
					className="mx-2 inline h-0.5 w-0.5 fill-current"
					aria-hidden="true"
				>
					<circle cx={1} cy={1} r={1} />
				</svg>
				You've been redirected to our secure Quote Request Form.
			</p>
			<LockClosedIcon className="w-4 h-4" />

			<div className="flex flex-1 justify-end">
				<button
					onClick={() => handleHidden(true)}
					type="button"
					className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
				>
					<span className="sr-only">Dismiss</span>
					<XMarkIcon className="h-5 w-5 " aria-hidden="true" />
				</button>
			</div>
		</div>
	);
};

export default FormBanner;
