import {SET_CABINET_ORDER_VIEW} from "./action";


const initState = {
    current: null,
}

export const orderReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_CABINET_ORDER_VIEW:
            return {...state, current: action.payload};
        default:
            return state;
    }
}