import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import AnimatedInputProxy from "../../../helpers/forms/AnimatedInputProxy";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../profileServices";

const NewAddressModal = ({ open, setOpen }) => {
	const [formValues, setFormValues] = useState({});
	const profile = useSelector((state) => state.cabinet.utils.profile);
	const dispatch = useDispatch();

	const handleFromValues = (values) => {
		const newFormValues = {
			...formValues,
			...values,
		};

		setFormValues(newFormValues);
	};

	const handleSubmit = () => {
		dispatch(
			updateUserProfile({
				...{ address: formValues },
				id: profile.id,
			})
		);

		setOpen(false);
		setFormValues({});
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
								<div className="space-y-4">
									<div className="ml-4 mt-2">
										<h3 className="text-base font-semibold leading-6 text-gray-900">
											Add new address
										</h3>
									</div>
									<AnimatedInputProxy
										{...{
											label: "Name",
											name: "name",
											onChange: (e) => {
												handleFromValues({ name: e.target.value });
											},
										}}
									/>
									<AnimatedInputProxy
										{...{
											label: "Address",
											name: "address",
											onChange: (e) => {
												handleFromValues({ full_address: e.target.value });
											},
										}}
									/>
								</div>
								<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
									<button
										type="button"
										className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
										onClick={() => handleSubmit()}
									>
										Submit
									</button>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
										onClick={() => setOpen(false)}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default NewAddressModal;
