const initState = {
	info: null,
	loading: true,
};

export const trackingReducer = (state = initState, action) => {
	switch (action.type) {
		case "SET_TRACKING_INFO":
			return { ...state, info: action.payload };
		case "LOADING_TRACKING":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
