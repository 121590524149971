const initState = {
	loading: true,
	error: false,
	success: false,
	data: null,
};

export const confirmationReducer = (state = initState, action) => {
	switch (action.type) {
		case "LOADING_CONFIRMATION":
			return { ...state, loading: action.payload };
		case "SET_CONFIRMATION_DATA":
			return { ...state, data: action.payload };
		case "SET_CONFIRMATION_ERROR":
			return { ...state, error: action.payload };
		case "SET_CONFIRMATION_SUCCESS":
			return { ...state, success: action.payload };
		default:
			return state;
	}
};
