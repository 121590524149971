import React, { useEffect } from "react";
import MenuNav from "../MenuNav";
import { connect, useDispatch } from "react-redux";
import Footer from "../Footer";
import { getCurrentUser } from "../../auth/login/service/loginService";

const PageLayout = ({ children, authUser }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (!authUser) {
			dispatch(getCurrentUser());
		}
	}, [authUser, dispatch]);

	return (
		<div className="min-h-full bg-gray-50">
			<MenuNav />
			<main className="relative isolate bg-gray-50 px-6 py-24 sm:py-32 lg:px-8 mx-auto max-w-7xl sm:px-6">
				<svg
					className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
					aria-hidden="true"
				>
					<defs>
						<pattern
							id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
							width={200}
							height={200}
							x="50%"
							y={-64}
							patternUnits="userSpaceOnUse"
						>
							<path d="M100 200V.5M.5 .5H200" fill="none" />
						</pattern>
					</defs>
					<svg x="50%" y={-64} className="overflow-visible fill-gray-50">
						<path
							d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M299.5 800h201v201h-201Z"
							strokeWidth={0}
						/>
					</svg>
					<rect
						width="100%"
						height="100%"
						strokeWidth={0}
						fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
					/>
				</svg>
				{children}
			</main>
			<Footer />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		authUser: state.cabinet.utils.user,
	};
};

export default connect(mapStateToProps, null)(PageLayout);
