import React from "react";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { login } from "./service/loginService";
import { connect } from "react-redux";

const buttonVariants = {
	hover: { scale: 1.05 },
};

const LoginForm = ({ login, setUsername }) => {
	const navigate = useNavigate();
	// const loading = useSelector((state) => state.cabinet.utils.loading);

	const [formValues, setFormValues] = React.useState({
		email: "",
		password: "",
	});

	const onChange = (e) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		// await Auth.signIn(formValues.email, formValues.password);
		login(formValues);
		setUsername(formValues.email); // state from parent component
	};

	return (
		<div className="mt-8">
			<div className="mt-6">
				<form
					action="#"
					onSubmit={onSubmit}
					method="POST"
					className="space-y-6"
				>
					<div>
						<label
							htmlFor="email"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Email address
						</label>
						<div className="mt-2">
							<input
								id="email"
								name="email"
								type="email"
								onChange={onChange}
								autoComplete="email"
								required
								className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="space-y-1">
						<label
							htmlFor="password"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Password
						</label>
						<div className="mt-2">
							<input
								id="password"
								name="password"
								type="password"
								onChange={onChange}
								autoComplete="current-password"
								required
								className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="flex items-center justify-between">
						<div className="flex items-center ">
							<input
								id="remember-me"
								name="remember-me"
								type="checkbox"
								onChange={onChange}
								className="cursor-pointer h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
							/>
							<label
								htmlFor="remember-me"
								className=" cursor-pointer ml-2 block text-sm text-gray-900"
							>
								Remember me
							</label>
						</div>

						<div className="text-sm">
							<span
								onClick={() => {
									navigate("/forgot-password");
								}}
								className="cursor-pointer font-medium text-sky-700 hover:text-sky-400"
							>
								Forgot your password?
							</span>
						</div>
					</div>

					<div>
						<motion.button
							variants={buttonVariants}
							whileHover="hover"
							type="submit"
							id="submit"
							className="flex cursor-pointer w-full justify-center rounded-md bg-sky-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
						>
							Sign in
						</motion.button>
						{/* Or
						<span
							onClick={() => navigate("/register")}
							className="cursor-pointer font-medium text-sky-700 hover:text-sky-400 ml-1"
						>
							register now!
						</span> */}
					</div>
				</form>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	login: (data, navigate) => dispatch(login(data, navigate)),
});

export default connect(null, mapDispatchToProps)(LoginForm);
