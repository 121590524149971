import { XCircleIcon } from "@heroicons/react/24/outline";

const getErrNo = (validation) => {
	let totalErrNum = 0;

	Object.keys(validation).forEach((key) => {
		if (!validation[key]) totalErrNum += 1;
	});

	return totalErrNum;
};

const ValidationMessageComponent = ({ validation }) => {
	let totalErrNum = getErrNo(validation);

	if (!totalErrNum) return null;

	return (
		<div className="rounded-md bg-red-50 p-4">
			<div className="flex">
				<div className="flex-shrink-0">
					<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
				</div>
				<div className="ml-3">
					<h3 className="text-sm font-medium text-red-800">
						There were {totalErrNum} rules{totalErrNum > 2 ? "s" : ""} you need
						to follow
					</h3>
					<div className="mt-2 text-sm text-red-700">
						<ul className="list-disc space-y-1 pl-5">
							{!validation.hasNumber && (
								<li>Must contain at least one number</li>
							)}
							{!validation.hasLowercase && (
								<li>Must contain at least one lowercase letter</li>
							)}
							{!validation.hasUppercase && (
								<li>Must contain at least one uppercase letter</li>
							)}
							{/* {!validation.hasSpecialChar && (
								<li>Must contain at least one special character</li>
							)} */}
							{!validation.hasValidLength && (
								<li>Must be at least 8 characters long</li>
							)}
							{!validation.isMatching && <li>Passwords must match</li>}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ValidationMessageComponent;
