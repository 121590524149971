import CabinetLoading from "../../components/CabinetLoading";
import React from "react";
import CabinetPageLayout from "../../components/CabinetPageLayout";

const ViewOrder = () => {
    return (
        <CabinetPageLayout>
            <CabinetLoading>
                ViewOrder
            </CabinetLoading>
        </CabinetPageLayout>
    )
}

export default ViewOrder;