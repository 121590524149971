import { loadingCabinet } from "../../util.reducer";
import { core } from "../../../index";
import { setCurrentListing } from "../reducer/action";

export const getCurrent = (page) => (dispatch) => {
	dispatch(loadingCabinet(true));

	core
		.get(`api/public/cabinet/current/?page=${page}`)
		.then(({ data }) => {
			dispatch(setCurrentListing(data));
			dispatch(loadingCabinet(false));
		})
		.catch((e) => {
			dispatch(loadingCabinet(false));
		});
};
