import {core} from "../index";

export const getTrackingData = (token) => dispatch => {
    dispatch({type: 'LOADING_TRACKING', payload: true})

    core.get('/api/public/tracking', {params: {token}})
        .then((r) => {
            dispatch({type: 'SET_TRACKING_INFO', payload: r.data})
            dispatch({type: 'LOADING_TRACKING', payload: false})
        });
}
