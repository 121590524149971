import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { applyMiddleware, createStore } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./index.reducer";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFoundPage from "./components/NotFoundPage";
import Home from "./home";
import axios from "axios";
import { setInterceptors } from "./helpers/axios";
import moment from "moment";
import en_GB from "antd/lib/locale-provider/en_GB";
import PageLayout from "./components/Layout";
import { Amplify } from "aws-amplify";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { awsConfig } from "./aws-exports";
import Tracking from "./tracking";
import Terms from "./terms";
import Confirmation from "./confirmation";
import Policy from "./policy";
import Request from "./request/page";
import Faq from "./faq/page";
import Login from "./auth/login/Login";
import ForgotPassword from "./auth/login/ForgotPassword";
import ForgotPasswordConfirm from "./auth/login/ForgotPasswordConfirm";
import CabinetRoutes from "./cabinet/CabinetRotues";
import PaymentRequest from "./payment-request";
import NewUserPasswordConfirm from "./auth/login/NewUserPasswordConfirm";
import About from "./about";
import Extras from "./extras";

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(
	reducers
	// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

Amplify.configure(awsConfig);

export const core = axios.create({
	baseURL:
		process.env.NODE_ENV === "production"
			? "https://api.seagoat.app"
			: "https://api.seagoat.local",
	withCredentials: true,
});

store.dispatch(setInterceptors());

moment.locale("en-gb", {
	week: {
		dow: 1,
	},
});

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<AmplifyProvider>
			<ConfigProvider locale={en_GB}>
				<Provider {...{ store }}>
					<BrowserRouter>
						<PageLayout>
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path="/tracking" element={<Tracking />} />
								<Route path="/tracking/:tokenId" element={<Tracking />} />
								<Route path="/request" element={<Request />} />
								<Route path="/:fqdn/request" element={<Request />} />
								<Route path="/faq" element={<Faq />} />
								<Route path="/about" element={<About />} />
								<Route path="/policy" element={<Policy />} />
								{/* <Route path="/pricing" element={<Pricing />} /> */}
								<Route path="/terms" element={<Terms />} />
								<Route path="/c/:tokenId" element={<Confirmation />} />
								<Route path="/p/:tokenId" element={<PaymentRequest />} />
								<Route path="/e/:tokenId" element={<Extras />} />
								<Route
									path="/confirmation/:tokenId"
									element={<Confirmation />}
								/>
								{/* <Route path="/request" element={<RequestPage />} />
								<Route
									path="/registration/:token"
									element={<RegistrationPage />}
								/> */}
								{/* <Route path=":dmn/request" element={<RequestPage />} />
								<Route path=":dmn/request/:type" element={<RequestPage />} />
								<Route
									path=":dmn/request/:collection/:delivery"
									element={<RequestPage />}
								/>
								<Route
									path=":dmn/request/confirmation/:confirmationId"
									element={<RequestPage />}
								/> */}
								<Route path="/login" element={<Login />} />
								<Route
									path="/login/new-user/password-confirm/:email/:token"
									element={<NewUserPasswordConfirm />}
								/>
								<Route path="/forgot-password" element={<ForgotPassword />} />
								<Route
									path="/forgot-password-confirm/:email"
									element={<ForgotPasswordConfirm />}
								/>
								{/* <Route path="/register/:token" element={<Register />} /> */}
								<Route path="/cabinet/*" element={<CabinetRoutes />} />
								{/* <Route path="/:tokenId" element={<Verification />} /> */}
								<Route path="*" element={<NotFoundPage />} />
								<Route path="not-found" element={<NotFoundPage />} />
							</Routes>
						</PageLayout>
					</BrowserRouter>
				</Provider>
			</ConfigProvider>
		</AmplifyProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
