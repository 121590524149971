import { useSelector } from "react-redux";

const ExtrasLoading = ({ children }) => {
	const loading = useSelector((state) => state.extras.loading);

	if (!loading) return children;
	return (
		<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 h-96 flex items-center justify-center">
			<div className="mx-auto max-w-3xl text-2xl tracking-tight">
				Loading ...
			</div>
		</div>
	);
};

export default ExtrasLoading;
