import React, { Fragment } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { logout } from "../../auth/login/service/loginService";
import { UserIcon } from "@heroicons/react/24/outline";
import logoImg from "../logos/logo2.png";

const navigation = [
	{
		key: "/",
		name: "Home",
	},

	// {
	// 	key: "/tracking",
	// 	name: "Tracking",
	// },
	// {
	// 	key: "/request",
	// 	name: "Request",
	// },
	{
		key: "/faq",
		name: "FAQ",
	},
	// {
	// 	key: "/policy",
	// 	name: "Policy",
	// },
	{
		key: "/about",
		name: "About",
	},
	{
		key: "/pricing",
		name: "Pricing",
	},
	// {
	// 	key: "/terms",
	// 	name: "Terms",
	// },
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const MenuNav = ({ user, loading }) => {
	const navigate = useNavigate();

	const userNavigation = [
		{
			name: "Dashboard",
			callback: () => {
				window.location.href = user.redirect_to;
			},
		},
		{
			name: "Sign out",
			callback: () => {
				logout();
			},
		},
	];

	return (
		<Disclosure
			as="nav"
			className="border-b border-sky-300 border-opacity-25 bg-sky-900 lg:border-ponone shadow-lg"
		>
			{({ open }) => (
				<>
					<div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
						<div className="relative flex h-16 items-center justify-between lg:border-b lg:border-sky-400 lg:border-opacity-25">
							<div className="flex items-center px-2 lg:px-0">
								<div className="flex-shrink-0 aspect-square rounded-full overflow-hidden">
									<img
										className="block h-12 w-12"
										src={logoImg}
										alt="Your Company"
									/>
								</div>
								<div className="hidden lg:ml-10 lg:block">
									<div className="flex space-x-4">
										{navigation.map((item, key) => (
											<div
												key={key}
												onClick={() => navigate(item.key)}
												className={classNames(
													item.current
														? "bg-sky-700 text-white"
														: "text-white bg-sky-800 hover:bg-sky-500 hover:bg-opacity-75",
													"rounded-md py-2 px-3 text-sm font-medium cursor-pointer"
												)}
												aria-current={item.current ? "page" : undefined}
											>
												{item.name}
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="flex lg:hidden">
								{/* Mobile menu button */}
								<Disclosure.Button className="cursor-pointer inline-flex items-center justify-center rounded-md bg-sky-600 p-2 text-sky-200 hover:bg-sky-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-600">
									{open ? (
										<XMarkIcon className="block h-6 w-6" aria-hidden="true" />
									) : (
										<Bars3Icon className="block h-6 w-6" aria-hidden="true" />
									)}
								</Disclosure.Button>
							</div>
							{!loading && (
								<div className="hidden lg:ml-4 lg:block">
									{!user && (
										<div className="flex items-center">
											<button
												onClick={() => navigate("/login")}
												className="text-white cursor-pointer bg-sky-800 hover:bg-sky-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
											>
												<span>Sign in</span>
											</button>
										</div>
									)}
									{user && (
										<div className="flex items-center">
											{/* <button
												type="button"
												className="flex-shrink-0 rounded-full bg-sky-800 p-1 text-sky-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-600"
											>
												<span className="sr-only">View notifications</span>
												<BellIcon className="h-6 w-6" aria-hidden="true" />
											</button> */}

											{/* Profile dropdown */}

											<Menu as="div" className="relative ml-3 flex-shrink-0">
												<div>
													<Menu.Button className="flex p-2 cursor-pointer rounded-full bg-sky-800 hover:bg-sky-500 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-600">
														<UserIcon className="h-6 w-6" />
														{/* <img
															className="h-8 w-8 rounded-full"
															src={user.imageUrl}
															alt=""
														/> */}
													</Menu.Button>
												</div>
												<Transition
													as={Fragment}
													enter="transition ease-out duration-100"
													enterFrom="transform opacity-0 scale-95"
													enterTo="transform opacity-100 scale-100"
													leave="transition ease-in duration-75"
													leaveFrom="transform opacity-100 scale-100"
													leaveTo="transform opacity-0 scale-95"
												>
													<Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
														{userNavigation.map((item) => (
															<Menu.Item key={item.name}>
																{({ active }) => (
																	<div
																		onClick={item.callback}
																		className={classNames(
																			active ? "bg-gray-100" : "",
																			"block px-4 py-2 text-sm text-gray-700"
																		)}
																	>
																		{item.name}
																	</div>
																)}
															</Menu.Item>
														))}
													</Menu.Items>
												</Transition>
											</Menu>
										</div>
									)}
								</div>
							)}
						</div>
					</div>

					<Disclosure.Panel className="lg:hidden">
						<div className="space-y-1 px-2 pb-3 pt-2">
							{navigation.map((item, key) => (
								<Disclosure.Button
									key={key}
									as="div"
									onClick={() => navigate(item.key)}
									className={classNames(
										item.current
											? "bg-sky-700 text-white"
											: "text-white hover:bg-sky-500 hover:bg-opacity-75",
										"block rounded-md py-2 px-3 text-base font-medium cursor-pointer"
									)}
									aria-current={item.current ? "page" : undefined}
								>
									{item.name}
								</Disclosure.Button>
							))}
						</div>
						{!user && (
							<div className="border-t border-sky-700 pb-3 pt-4 pl-2">
								<Disclosure.Button
									as="div"
									onClick={() => navigate("/login")}
									className={classNames(
										"text-white hover:bg-sky-500 hover:bg-opacity-75",
										"block rounded-md py-2 px-3 text-base font-medium cursor-pointer"
									)}
									aria-current={undefined}
								>
									Sign in
								</Disclosure.Button>
							</div>
						)}

						{user && (
							<div className="border-t border-sky-700 pb-3 pt-4">
								<div className="flex items-center px-5">
									{/* <div className="flex-shrink-0">
										<img
											className="h-10 w-10 rounded-full"
											src={user.imageUrl}
											alt=""
										/>
									</div> */}
									<div className="ml-3">
										<div className="text-base font-medium text-white">
											{user.name} name
										</div>
										<div className="text-sm font-medium text-gray-300">
											{user.email}
										</div>
									</div>
									{/* <button
										type="button"
										className="ml-auto flex-shrink-0 rounded-full bg-sky-600 p-1 text-sky-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-600"
									>
										<span className="sr-only">View notifications</span>
										<BellIcon className="h-6 w-6" aria-hidden="true" />
									</button> */}
								</div>
								<div className="mt-3 space-y-1 px-2">
									{userNavigation.map((item) => (
										<Disclosure.Button
											key={item.name}
											onClick={item.callback}
											as="div"
											className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-sky-500 hover:bg-opacity-75"
										>
											{item.name}
										</Disclosure.Button>
									))}
								</div>
							</div>
						)}
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.cabinet.utils.user,
		loading: state.cabinet.utils.loadingAuth,
	};
};

export default connect(mapStateToProps)(MenuNav);
