import Accordion from "./Accordion";
import RequestFormLayout from "./RequestFormLayout";

const ItemFormV2 = () => {
	return (
		<RequestFormLayout>
			<h2 className="text-2xl font-semibold text-center leading-7 pb-2 text-gray-900">
				Request a quote
			</h2>
			<div>
				<Accordion />
			</div>
		</RequestFormLayout>
	);
};

export default ItemFormV2;
