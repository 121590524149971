import { useEffect, useState } from "react";
import CabinetPageLayout from "../components/CabinetPageLayout";
import AddressActionPanel from "./AddressActionPanel";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, updateUserProfile } from "./profileServices";
import AnimatedInputProxy from "../../helpers/forms/AnimatedInputProxy";

const Profile = () => {
	const [formValues, setFormValues] = useState({});
	const profile = useSelector((state) => state.cabinet.utils.profile);
	const dispatch = useDispatch();
	const loadingProfile = useSelector(
		(state) => state.cabinet.utils.loadingProfile
	);

	useEffect(() => {
		document.title = "Profile | Delivery Network";

		dispatch(getUserProfile());
	}, [dispatch]);

	const handleFromValues = (values) => {
		const newFormValues = {
			...formValues,
			...values,
		};

		setFormValues(newFormValues);
	};

	const onBlurValues = () => {
		dispatch(updateUserProfile(formValues));
	};

	return (
		<CabinetPageLayout>
			{!loadingProfile && (
				<form>
					<div className="mx-4 mt-8">
						<div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
							<div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
								<div className="ml-4 mt-2">
									<h3 className="text-base font-semibold leading-6 text-gray-900">
										Profile
									</h3>
								</div>
							</div>
							<div className="space-y-4 mt-6">
								{profile?.full_name && (
									<div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<dt className="text-sm font-medium leading-6 text-gray-900">
											Full name
										</dt>
										<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
											{profile.full_name}
										</dd>
									</div>
								)}
								{!profile?.full_name && (
									<div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<AnimatedInputProxy
											{...{
												className:
													"px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0",
												label: "Full name",
												name: "full_name",
												onChange: (e) => {
													handleFromValues({ full_name: e.target.value });
												},
												onBlur: (e) => {
													onBlurValues({});
												},
											}}
										/>
									</div>
								)}
								{profile?.email && (
									<div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<dt className="text-sm font-medium leading-6 text-gray-900">
											Email
										</dt>
										<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
											{profile.email}
										</dd>
									</div>
								)}
								{!profile?.email && (
									<div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<AnimatedInputProxy
											{...{
												label: "Email",
												name: "email",
												onChange: (e) => {
													handleFromValues({ email: e.target.value });
												},
												onBlur: (e) => {
													onBlurValues({});
												},
											}}
										/>
									</div>
								)}
								{profile?.landline && (
									<div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<dt className="text-sm font-medium leading-6 text-gray-900">
											Landline
										</dt>
										<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
											{profile.landline}
										</dd>
									</div>
								)}
								{!profile?.landline && (
									<div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<AnimatedInputProxy
											{...{
												label: "Landline",
												name: "landline",
												onChange: (e) => {
													handleFromValues({ landline: e.target.value });
												},
												onBlur: (e) => {
													onBlurValues({});
												},
											}}
										/>
									</div>
								)}
								{profile?.mobile && (
									<div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<dt className="text-sm font-medium leading-6 text-gray-900">
											Mobile
										</dt>
										<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
											{profile.mobile}
										</dd>
									</div>
								)}
								{!profile?.mobile && (
									<div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<AnimatedInputProxy
											{...{
												label: "Mobile",
												name: "mobile",
												onChange: (e) => {
													handleFromValues({ mobile: e.target.value });
												},
												onBlur: (e) => {
													onBlurValues({});
												},
											}}
										/>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="mx-4 mt-8">
						<AddressActionPanel {...{ profile }} />
					</div>
				</form>
			)}
		</CabinetPageLayout>
	);
};

export default Profile;
