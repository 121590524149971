import PasswordForm from "./PasswordForm";
import LoadingForm from "./LoadingForm";
import { useEffect, useState } from "react";
import { core } from "../../..";
import { useParams } from "react-router";

const NewUserPasswordConfirm = () => {
	const { email, token } = useParams();
	const [loading, setLoading] = useState(true);
	const [session, setSession] = useState(null);
	// const navigate = useNavigate();

	useEffect(() => {
		core
			.get(`api/public/user/new/${email}/${token}`)
			.then((r) => {
				if (r?.data?.session) {
					setSession(r.data.session);
				}
			})
			.finally(() => setLoading(false));
	}, [email, token]);

	return (
		<div className="mx-auto max-w-4xl sm:px-6 lg:px-8">
			<div className="flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
				<div className="border-b border-gray-900/10 pb-12 pt-6 sm:pt-8 mb-12">
					<div className="overflow-hidden bg-white shadow sm:rounded-lg  pt-4 px-8 ">
						<div className="mx-auto w-full max-w-sm lg:w-96 pb-5">
							<LoadingForm {...{ loading }}>
								<div>
									<h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 text-center">
										Please enter you password
									</h2>
								</div>
								<PasswordForm {...{ session }} />
							</LoadingForm>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewUserPasswordConfirm;
