import { core } from "../../index";
import {
	requestFormLoading,
	requestFormShowError,
	requestFormShowSuccess,
	requestPageLoading,
	requestPagePriceTierLoading,
	setRequestFormData,
	setRequestFormSearchAddress,
} from "../reducer/action";

export const checkQuery = (query) => (dispatch) => {
	dispatch(requestPageLoading(true));
	core
		.post(`api/public/checkQuery`, { query })
		.then((r) => dispatch(setRequestFormData(r)))
		.finally(() => {
			dispatch(requestPageLoading(false));
		});
};

export const postcodeAddressSearch = (query) => (dispatch) => {
	// dispatch(loadingCabinet(true))
	core
		.get(`api/public/postcode-address-search?query=${query}`)
		.then((r) => dispatch(setRequestFormSearchAddress(r.data)))
		.finally(
			() => {}
			// dispatch(loadingView(false))
		);
};

export const addressSearch = (query) => (dispatch) => {
	// dispatch(loadingCabinet(true))
	core
		.get(`api/public/address-search?query=${query}`)
		.then((r) =>
			dispatch(setRequestFormSearchAddress(r.data))
		)
		.finally(
			() => {}
			// dispatch(loadingView(false))
		);
};

export const submitRequest = (values) => (dispatch) => {
	dispatch(requestFormLoading(true));

	core
		.post(`api/public/request`, values, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
		.then(() => dispatch(requestFormShowSuccess(true)))
		.catch(() => {
			dispatch(requestFormShowError(true));
			dispatch(requestFormShowSuccess(false));
		})
		.finally(() => dispatch(requestFormLoading(false)));
};

export const getRequestData = (query) => (dispatch) => {
	dispatch(requestPageLoading(true));

	core
		.get(`api/public/request`)
		.then((r) => dispatch(setRequestFormData(r.data)))
		.then(() => {
			query && dispatch(checkQuery(query));
		})
		.finally(() => {
			dispatch(requestPageLoading(false));
		});
};

export const getPricingTiers =
	(tearing, formValues) => (dispatch) => {
		// const formDataState = _.cloneDeep(getState().request.formData);
		dispatch(requestPagePriceTierLoading(true));
		core
			.post(`api/public/request/pricing-tiers`, { tearing, formValues })
			// .then((r) => dispatch(setRequestFormData({ ...r.data, formDataState })))
			.finally(() => {
				dispatch(requestPageLoading(false));
				dispatch(requestPagePriceTierLoading(false));
			});
	};
