import {core} from "../../index";
import {setAuthUser} from "../../cabinet/util.reducer";

export const setInterceptors = () => {
    return (dispatch, getState) => {
        core.interceptors.request.use(
            config => {
                config.headers.Authorization = `Bearer ${localStorage.getItem("cabinet_access_token")}`;
                return config;
            },
            error => {
                //return Promise.reject(error);
            }
        );

        core.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (error.response) {
                    if (error.response.status === 401 && window.location.href !== '/login') {
                        dispatch(setAuthUser(null))
                        // window.location.href = '/login';
                        // history.push('/login');
                        // dispatch(sessionOutcome("expired"));
                    } else if (error.response.status === 403) {
                        // dispatch(sessionOutcome("kicked"));
                    } else if (error.response.status === 405) {
                        // window.location.replace("/login");
                    }
                }
                return Promise.reject(error);
            }
        );
    };
};