import React, { useState } from "react";
import PhotoModal from "./PhotoModel";
import { useSelector } from "react-redux";

export function PhotoViewer() {
	const [open, setOpen] = useState(false);
	const [image, setImage] = useState(false);
	const extras = useSelector((state) => state.extras);

	return (
		<>
			<PhotoModal {...{ open, setOpen, image }} />
			<div className="bg-gradient-to-b text-center md:text-left py-10">
				<div className="mx-auto max-w-3xl">
					<div className="max-w-xl">
						<p className="mt-2 text-4xl font-bold tracking-tight">Photos</p>
					</div>
					<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 sm:px-5 mt-5 pb-10">
						{(extras?.data.photos || []).map((image) => (
							<div
								key={image.title}
								onClick={() => {
									setOpen(true);
									setImage(image);
								}}
								className="shadow-md shadow-gray-600 rounded-lg overflow-hidden hover:cursor-pointer"
							>
								<img
									src={image.url}
									alt={image.title}
									className="rounded-md duration-200 hover:scale-105"
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
}
