import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { updateConfirmation } from "../confirmation.service";
import moment from "moment";
import ConfirmationOptions from "./ConfirmationOptions";
import ConfirmationDestination from "./ConfirmationDestination";

const ConfirmationForm = () => {
	const dispatch = useDispatch();

	const data = useSelector((state) => state.confirmation.data);

	const getDateTime = ({ info }) => {
		const date =
			info.operation?.route?.date &&
			moment(info.operation.route.date).format("DD/MM/YYYY");

		const time = info.operation?.time_agreed ?? "";

		return `${time} ${date}`;
	};

	const getPostcode = ({ info }) => {
		return info.operation.station.postcode ?? "";
	};

	const getAddress = ({ info }) => {
		return info.operation.station.address_line_1 ?? "";
	};

	const [formState, setFormState] = useState({
		date: getDateTime(data),
		address: `${getPostcode(data)} ${getAddress(data)}`,
		message: "",
	});

	const [validationErrors, setValidationErrors] = useState({});

	const handleChange = (event) => {
		const { name, value } = event?.target;
		setFormState({ ...formState, [name]: value });
		setValidationErrors({});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		formState.operation_id = data.info.operation.id;
		formState.station_id = data.info.operation.station.id;

		const required = data.details?.require_description_option_ids?.split(",");

		// eslint-disable-next-line eqeqeq
		const requiredDetailsMissing = required?.find((r) => r == formState.option);
		if (requiredDetailsMissing && !formState.client_notes) {
			setValidationErrors({ requiredMissing: true });
		} else if (!formState.option) {
			setValidationErrors({ optionsMissing: true });
		} else {
			dispatch(updateConfirmation(formState));
		}
	};

	const openNewWindow = () => {
		window.open("/policy", "_blank", "noopener,noreferrer");
	};

	return (
		<form onSubmit={handleSubmit} className="lg:flex-auto ">
			<ConfirmationDestination {...{ formState }} />
			<ConfirmationOptions {...{ formState, handleChange, validationErrors }} />
			{validationErrors.optionsMissing && (
				<div className="text-red-400 font-semibold mt-5">
					Please select from options above
				</div>
			)}
			<div className={"max-w-xl mx-auto"}>
				<div className="mt-10">
					<button
						type="submit"
						className="block w-full rounded-md bg-sky-600 px-3.5 py-2.5 text-center text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
					>
						Confirm
					</button>
				</div>
				<p className="mt-4 text-sm text-center leading-6 text-gray-500">
					By submitting this form, I agree to the
					<span
						onClick={() => openNewWindow()}
						className="font-semibold text-sky-600 inline ml-1 hover:cursor-pointer hover:text-sky-400"
					>
						privacy&nbsp;policy
					</span>
				</p>
			</div>
		</form>
	);
};

export default ConfirmationForm;
