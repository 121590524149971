const Policy = () => {
	return (
		<div className="bg-white px-6 py-32 lg:px-8">
			{" "}
			<div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
				{" "}
				<p className="text-base font-semibold leading-7 text-sky-600">
					{" "}
					Privacy Policy for Seagoat App{" "}
				</p>{" "}
				<h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl">
					{" "}
					Welcome to Seagoat app{" "}
				</h1>{" "}
				<p className="mt-6 text-xl leading-8">
					{" "}
					A digital platform designed to facilitate logistics services. This
					Privacy Policy is intended to inform you about how we collect, use,
					and share information through our web application{" "}
				</p>{" "}
				<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-800">
					{" "}
					1. Information We Collect{" "}
				</h2>{" "}
				<div className="mt-4 max-w-2xl">
					{" "}
					<p>
						{" "}
						We may collect information that you provide directly to us when you
						register for an account, use our services, or communicate with us.
						This information may include your name, email address, phone number,
						and any other details you choose to provide.{" "}
					</p>{" "}
				</div>{" "}
				<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-800">
					{" "}
					2. How We Use Your Information{" "}
				</h2>{" "}
				<div className="mt-4 max-w-2xl">
					{" "}
					<p>
						{" "}
						The information we collect is used to provide, maintain, and improve
						our services, to communicate with you, and to personalize your
						experience. We may also use the information to protect against fraud
						and abuse and to comply with legal obligations.{" "}
					</p>{" "}
				</div>{" "}
				<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-800">
					{" "}
					3. Sharing Your Information{" "}
				</h2>{" "}
				<div className="mt-4 max-w-2xl">
					{" "}
					<p>
						{" "}
						We do not sell or rent your personal information to third parties.
						We may share your information with service providers who perform
						services on our behalf, in compliance with this Privacy Policy, and
						in a manner that is consistent with applicable privacy laws.{" "}
					</p>{" "}
				</div>{" "}
				<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-800">
					{" "}
					4. Data Security{" "}
				</h2>{" "}
				<div className="mt-4 max-w-2xl">
					{" "}
					<p>
						{" "}
						We implement reasonable measures to help protect your information
						from loss, theft, misuse, and unauthorized access, disclosure,
						alteration, and destruction.{" "}
					</p>{" "}
				</div>{" "}
				<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-800">
					{" "}
					5. User Responsibilities{" "}
				</h2>{" "}
				<div className="mt-4 max-w-2xl">
					{" "}
					<p>
						{" "}
						Seagoat App acts as a platform for facilitating logistics services.
						We do not take responsibility for any orders, services, or
						transactions initiated or completed by app users. Users are solely
						responsible for the legality, reliability, and integrity of their
						orders and transactions. It is the user's responsibility to ensure
						they comply with all applicable laws and regulations in their use of
						Seagoat App.{" "}
					</p>{" "}
				</div>{" "}
				<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-800">
					{" "}
					6. Changes to This Privacy Policy{" "}
				</h2>{" "}
				<div className="mt-4 max-w-2xl">
					{" "}
					<p>
						{" "}
						Seagoat App reserves the right to update or modify this Privacy
						Policy at any time and without prior notice. We encourage you to
						review this Privacy Policy periodically to stay informed about how
						we are protecting the information we collect. Your continued use of
						our services after any changes or revisions to this Privacy Policy
						signifies your agreement with the terms of the updated or revised
						Privacy Policy. It is your responsibility to check this Privacy
						Policy regularly for changes.{" "}
					</p>{" "}
				</div>{" "}
				<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-800">
					{" "}
					7. Contact Us{" "}
				</h2>{" "}
				<div className="mt-4 max-w-2xl">
					{" "}
					<p>
						{" "}
						If you have any questions about this Privacy Policy, please contact
						us at support@seagoat.app{" "}
					</p>{" "}
				</div>{" "}
			</div>{" "}
		</div>
	);
};
export default Policy;
