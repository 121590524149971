import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useParams } from "react-router";
import ValidationMessageComponent from "./ValidateMessageComponent";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { core } from "../../..";
import _ from "lodash";

const buttonVariants = {
	hover: { scale: 1.05 },
};

const checkPasswordRules = (password, confirmPassword) => {
	const rules = {
		hasNumber: /\d/.test(password),
		hasLowercase: /[a-z]/.test(password),
		hasUppercase: /[A-Z]/.test(password),
		// hasSpecialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password),
		hasValidLength: password.length >= 8,
		isMatching: password === confirmPassword,
	};

	return rules;
};

const PasswordForm = ({ session }) => {
	const { email, token } = useParams();
	const [validation, setValidation] = React.useState({});
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState("");

	useEffect(() => {
		const rules = checkPasswordRules(password, confirmPassword);
		setValidation(rules);
	}, [password, confirmPassword]);

	const onSubmit = async (e) => {
		e.preventDefault();
		const formValidations = Object.values(validation);
		const invalid = formValidations.find((v) => v === false);

		if (!invalid)
			core
				.post(`api/public/user/new/confirm/${email}`, {
					token,
					password,
					session,
				})
				.then((r) => {
					if (r?.data?.redirect_to) {
						window.location.href = r.data.redirect_to;
					}
				});
	};

	if (!session) return null;

	return (
		<form>
			<div className="space-y-2 mt-8">
				<label
					htmlFor="email"
					className="block text-sm font-medium leading-6 text-gray-900"
				>
					Email
				</label>
				<div className="">
					<input
						id="email"
						name="email"
						type="text"
						autoComplete="email"
						value={email}
						required
						disabled
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
					/>
				</div>

				<div>
					<label
						htmlFor="password"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Password
					</label>
					<div className="mt-2 flex rounded-md shadow-sm">
						<div className="relative flex flex-grow items-stretch focus-within:z-10">
							<input
								type={showPassword ? "text" : "password"}
								name="password"
								autoComplete="new-password"
								id="password"
								onChange={(e) => setPassword(e.target.value)}
								className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
							/>
						</div>
						<button
							type="button"
							onClick={() => setShowPassword(!showPassword)}
							className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						>
							{showPassword && (
								<EyeSlashIcon
									className="-ml-0.5 h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							)}
							{!showPassword && (
								<EyeIcon
									className="-ml-0.5 h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							)}
						</button>
					</div>
				</div>

				<div>
					<label
						htmlFor="confirm_password"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Confirm Password
					</label>
					<div className="mt-2 flex rounded-md shadow-sm">
						<div className="relative flex flex-grow items-stretch focus-within:z-10">
							<input
								type={showConfirmPassword ? "text" : "password"}
								name="confirm_password"
								autoComplete="new-password"
								id="confirm_password"
								onChange={(e) => setConfirmPassword(e.target.value)}
								className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
							/>
						</div>
						<button
							type="button"
							onClick={() => setShowConfirmPassword(!showConfirmPassword)}
							className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						>
							{showConfirmPassword && (
								<EyeSlashIcon
									className="-ml-0.5 h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							)}
							{!showConfirmPassword && (
								<EyeIcon
									className="-ml-0.5 h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							)}
						</button>
					</div>
				</div>

				<ValidationMessageComponent {...{ validation }} />

				<div>
					<motion.button
						variants={buttonVariants}
						onClick={onSubmit}
						whileHover="hover"
						disabled={_.isEmpty(validation)}
						type="submit"
						className="flex w-full mt-4 justify-center rounded-md bg-sky-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
					>
						Submit
					</motion.button>
				</div>
			</div>
		</form>
	);
};

export default PasswordForm;
