import { CheckIcon } from "@heroicons/react/20/solid";

const features = [
	{
		name: "Notifications",
		description:
			"Get real-time alerts, track order statuses, and receive critical updates directly within the app.",
	},
	{
		name: "Reporting",
		description:
			"Create detailed reports on inventory, orders, and operational metrics for informed, data-driven decisions.",
	},
	{
		name: "Mobile App",
		description:
			"Manage your logistics data anywhere with our fully-featured mobile app, ensuring you stay connected on the go.",
	},
	{
		name: "Real-Time Tracking",
		description:
			"Monitor your fleet in real-time for efficient and timely deliveries.",
	},
	{
		name: "Logistics Automation",
		description:
			"Automate your logistics processes to save time and reduce errors.",
	},
	{
		name: "Customizations",
		description:
			"Enjoy a variety of customization options to tailor the app to your needs.",
	},
	{
		name: "Versatile Delivery Support",
		description:
			"Support for all delivery types, ensuring flexibility and efficiency.",
	},
];

export default function LongFeatureSection() {
	return (
		<div className=" py-24 sm:py-32">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
					<div>
						<h2 className="text-base font-semibold leading-7 text-sky-600">
							Everything You Need
						</h2>
						<p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
							All-In-One Platform
						</p>
						<p className="mt-6 text-base leading-7 text-gray-600">
							Streamline your operations with our comprehensive platform
							designed to meet all your logistics needs efficiently and
							effectively.
						</p>
					</div>
					<dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
						{features.map((feature) => (
							<div key={feature.name} className="relative pl-9">
								<dt className="font-semibold text-gray-900">
									<CheckIcon
										className="absolute left-0 top-1 h-5 w-5 text-sky-500"
										aria-hidden="true"
									/>
									{feature.name}
								</dt>
								<dd className="mt-2">{feature.description}</dd>
							</div>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}
