import { motion } from "framer-motion";
import React from "react";
import { connect } from "react-redux";
import { initPasswordReset } from "./service/loginService";
import { useNavigate } from "react-router";

const containerVariants = {
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { duration: 0.5 } },
};

const buttonVariants = {
	hover: { scale: 1.05 },
};

const ForgotPasswordForm = ({ response, initPasswordReset }) => {
	const navigate = useNavigate();
	const [formValues, setFormValues] = React.useState({
		email: "",
	});

    
	const onChange = (e) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<motion.div
			variants={containerVariants}
			initial="hidden"
			animate="visible"
			className="flex py-7 sm:py-15"
		>
			<div className="flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
				<div className="border-b border-gray-900/10 pb-12 pt-6 sm:pt-8 mb-12">
					<div className="overflow-hidden bg-white shadow sm:rounded-lg  pt-4 px-8 ">
						<div className="mx-auto w-full max-w-sm lg:w-96 pb-5">
							<div>
								<h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 text-center">
									{response?.message ? response.message : "Forgot Password"}
								</h2>
							</div>
							<div className="space-y-1 mt-8">
								<label
									htmlFor="email"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Your Email
								</label>
								<div className="mt-2">
									<input
										id="email"
										name="email"
										type="email"
										onChange={onChange}
										required
										className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
									/>
								</div>
							</div>
							<div className="mt-5">
								<motion.button
									variants={buttonVariants}
                                    onClick={() => initPasswordReset(formValues, navigate)}
									whileHover="hover"
									type="submit"
									className="flex w-full justify-center rounded-md bg-sky-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
								>
									Reset Password 
								</motion.button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
};

const mapStateToProps = (state) => {
	return {
		response: state.cabinet.utils.response,
		user: state.cabinet.utils.authUser,
		loading: state.cabinet.utils.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	initPasswordReset: (data, navigate) => dispatch(initPasswordReset(data, navigate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
