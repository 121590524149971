/* Request page */
export const SET_REQUEST_FORM_LOADING = "SET_REQUEST_FORM_LOADING";
export const SET_REQUEST_FORM_DATA = "SET_REQUEST_FORM_DATA";
export const SET_REQUEST_FORM_SEARCH_ADDRESS =
	"SET_REQUEST_FORM_SEARCH_ADDRESS";
export const SET_REQUEST_FORM_SHOW_ERROR = "SET_SHOW_ERROR";
export const SET_REQUEST_FORM_SHOW_SUCCESS = "SET_SHOW_SUCCESS";
export const SET_REQUEST_PAGE_LOADING = "SET_REQUEST_PAGE_LOADING";
export const SET_REQUEST_PRICE_TIER_LOADING = "SET_REQUEST_PRICE_TIER_LOADING";

export const requestFormShowError = (payload) => ({
	type: SET_REQUEST_FORM_SHOW_ERROR,
	payload,
});

export const requestFormShowSuccess = (payload) => ({
	type: SET_REQUEST_FORM_SHOW_SUCCESS,
	payload,
});

export const requestFormLoading = (payload) => ({
	type: SET_REQUEST_FORM_LOADING,
	payload,
});

export const requestPageLoading = (payload) => ({
	type: SET_REQUEST_PAGE_LOADING,
	payload,
});

export const setRequestFormSearchAddress = (payload) => ({
	type: SET_REQUEST_FORM_SEARCH_ADDRESS,
	payload,
});

export const setRequestFormData = (payload) => ({
	type: SET_REQUEST_FORM_DATA,
	payload,
});

export const requestPagePriceTierLoading = (payload) => ({
	type: SET_REQUEST_PRICE_TIER_LOADING,
	payload,
});
