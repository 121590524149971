import { useEffect } from "react";
import CabinetPageLayout from "../../components/CabinetPageLayout";
import CabinetLoading from "../../components/CabinetLoading";
import { useDispatch, useSelector } from "react-redux";
import { getPayments } from "../service/paymentService";
import PaymentsTable from "./PaymentsTable";
import Pagination from "../../components/Pagination";
import CabinetEmptySpace from "../../components/CabinetEmptySpace";

const Payments = () => {
	const dispatch = useDispatch();
	const payments = useSelector((state) => state.cabinet.payments.orders);

	const {
		data,
		current_page,
		first_page_url,
		last_page_url,
		links,
		total,
		to,
		from,
		last_page,
	} = payments;

	useEffect(() => {
		document.title = "Payments | Delivery Network";

		dispatch(getPayments(1));
	}, [dispatch]);

	return (
		<CabinetPageLayout>
			<CabinetLoading>
				<CabinetEmptySpace data={data}>
					<PaymentsTable {...{ data }} />
					<Pagination
						{...{
							current_page,
							first_page_url,
							last_page_url,
							last_page,
							links,
							total,
							to,
							from,
							serviceCall: getPayments,
						}}
					/>
				</CabinetEmptySpace>
			</CabinetLoading>
		</CabinetPageLayout>
	);
};

export default Payments;
