import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getCurrentUser } from "./login/service/loginService";

const AuthGuard = ({ children }) => {
	const dispatch = useDispatch();
	const navigator = useNavigate();
	const user = useSelector((state) => state.cabinet.utils.user);
	const loading = useSelector((state) => state.cabinet.utils.loadingAuth);

	useEffect(() => {
		dispatch(getCurrentUser());
	}, [dispatch]);

	if (loading)
		return (
			<div className="flex justify-center items-center h-96">
				<div className="w-12 h-12 border-2 border-t-8 border-sky-800 rounded-full animate-spin"></div>
			</div>
		);

	if (!user) return navigator("/login");

	return children;
};

export default AuthGuard;
