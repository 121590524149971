import { combineReducers } from "redux";
import { requestReducer } from "./request/reducer/requestReducer";
import cabinetReducer from "./cabinet.reducer";
import { registerReducer } from "./auth/register/reducer/registerReducer";
import { verificationReducer } from "./verification/verification.reducer";
import { confirmationReducer } from "./confirmation/confirmation.reducer";
import { trackingReducer } from "./tracking/reducer";
import { extrasReducer } from "./extras/extras.reducer";

const rootReducer = combineReducers({
	request: requestReducer,
	register: registerReducer,
	cabinet: cabinetReducer,
	verification: verificationReducer,
	confirmation: confirmationReducer,
	tracking: trackingReducer,
	extras: extrasReducer,
});

export default rootReducer;
