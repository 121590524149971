import { useDispatch, useSelector } from "react-redux";
import ExtrasLayout from "./ExtrasLayout";
import { useEffect } from "react";
import { useParams } from "react-router";
import { getExtras } from "./extras.service";
import ExtrasError from "./ExtrasError";
import ExtrasLoading from "./ExtrasLoading";
import TrackingBoard from "../tracking/TrackingBoard";
import { PhotoViewer } from "./PhotoViewer";





const Extras = () => {
	const { tokenId } = useParams();
	const dispatch = useDispatch();
	const extras = useSelector((state) => state.extras);

	useEffect(() => {
		dispatch(getExtras({ tokenId }));
	}, [tokenId, dispatch]);

	const DocComp = ({ title, subTitle, url }) => (
		<div className="mx-auto max-w-3xl py-10">
			<div className="max-w-xl px-4">
				<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
					{title}
					<br />
				</h2>
				<p className="mt-4 text-lg text-gray-500">{subTitle}</p>
				<div className="mt-10 flex items-center gap-x-6">
					<a
						href={url}
						className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						Download
					</a>
					{/* <a href="/" className="text-sm font-semibold leading-6 text-gray-900">
					Learn more <span aria-hidden="true">→</span>
				</a> */}
				</div>
			</div>
		</div>
	);

	const components = [
		{
			order: 0,
			key: "tracking",
			component: <TrackingBoard />,
		},
		{
			order: 1,
			key: "photo",
			component: <PhotoViewer />,
		},
		{
			order: 2,
			key: "invoice",
			component: (
				<DocComp
					{...{
						title: "Invoice",
						subTitle: "Click on the button to download your invoice",
						url: `https://api.seagoat.app/api/public/invoice/${tokenId}`,
					}}
				/>
			),
		},
		{
			order: 3,
			key: "receipt",
			component: (
				<DocComp
					{...{
						title: "Receipt",
						subTitle: "Click on the button to download your receipt",
						url: `https://api.seagoat.app/api/public/receipt/${tokenId}`,
					}}
				/>
			),
		},
	];

	const levels = extras?.data?.extras.split(",");

	return (
		<ExtrasLayout>
			<ExtrasError>
				<ExtrasLoading>
					{levels
						?.map((level) => components.find((c) => c.key === level))
						.filter(Boolean)
						.sort((a, b) => a.order - b.order)
						.map((component, idx) => (
							<div
								className={idx > 0 ? "border-t border-gray-200" : null}
								key={idx}
							>
								{" "}
								{component.component}{" "}
							</div>
						))}
				</ExtrasLoading>
			</ExtrasError>
		</ExtrasLayout>
	);
};

export default Extras;
