import moment from "moment";

const StatusBar = ({ status }) => {
	console.log({
		status,
	});

	return (
		<div className="px-4 py-6 sm:px-6 lg:p-8">
			<h4 className="sr-only">Status</h4>
			<p className="text-sm font-medium text-gray-900">
				{status.details.title} on{" "}
				<time dateTime={moment().format("DD/MM/YYYY")}>
					{moment().format("DD/MM/YYYY")}
				</time>
			</p>
			<div className="mt-6" aria-hidden="true">
				<div className="overflow-hidden rounded-full bg-gray-200">
					<div
						className="h-2 rounded-full bg-green-600"
						style={{ width: `calc((${status.step} * 2 + 1) / 20 * 100%)` }}
					/>
				</div>
				<div className="mt-6 hidden grid-cols-8 text-sm font-medium text-gray-600 sm:grid">
					<div className="text-green-600">Order placed</div>
					<div
						className={
							"text-center" + (status.step > 0 ? " text-green-600" : "")
						}
					>
						Order Confirmed
					</div>
					<div
						className={
							"text-center" + (status.step > 1 ? " text-green-600" : "")
						}
					>
						Order Scheduled
					</div>
					<div
						className={
							"text-center" + (status.step > 3 ? " text-green-600" : "")
						}
					>
						Out for Collection
					</div>
					<div
						className={
							"text-center" + (status.step > 4 ? " text-green-600" : "")
						}
					>
						Order Collected
					</div>
					<div
						className={
							"text-center" + (status.step > 5 ? " text-green-600" : "")
						}
					>
						Stored in Warehouse
					</div>
					<div
						className={
							"text-center" + (status.step > 6 ? " text-green-600" : "")
						}
					>
						Out for Delivery
					</div>
					<div
						className={
							"text-center" + (status.step > 7 ? " text-green-600" : "")
						}
					>
						Delivered
					</div>
				</div>
			</div>
		</div>
	);
};

export default StatusBar;
