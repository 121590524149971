import React, { useState } from "react";

const AnimatedSelectProxy = ({ label, options, onChange }) => {
	const [selectedOption, setSelectedOption] = useState("");

	const handleSelectChange = (e) => {
		const value = e.target.value;
		setSelectedOption(value);
		onChange(value);
	};

	return (
		<div className="relative">
			<select
				id={label}
				name={label}
				onChange={handleSelectChange}
				value={selectedOption}
				className={`
			rounded-md peer w-full h-10 border-b-2
			border-gray-300 focus:outline-none has-value focus:border-sky-500
		  `}
			>
				<option value="" disabled hidden></option>
				{options?.map((option) => {
					return (
						<option key={option.id} value={option.id}>
							{option.title}
						</option>
					);
				})}
			</select>
			<label
				htmlFor={label}
				className={`
			absolute bg-white left-4 transition-all duration-100 ease-in-out select-none pointer-events-none
				text-gray-500 ${!!selectedOption ? "-top-2.5 text-xs" : "px-0 top-2"}
			peer-placeholder-shown:text-gray-600 peer-placeholder-shown:text-xs
			peer-placeholder-shown:top-2 peer-placeholder-shown:left-0 peer-focus:-top-2.5
			peer-focus:text-sky-500 peer-focus:text-xs peer-focus:left-4
		  `}
			>
				{label}
			</label>
		</div>
	);
};

export default AnimatedSelectProxy;

/* 
			<select
				id={label}
				name={label}
				onChange={handleOnChange}
				value={selectedOption}
				className={`
				peer
          
        `}
			>
				<option value="" disabled></option>
				{options?.map((option) => {
					return (
						<option key={option.id} value={option.id}>
							{option.title}
						</option>
					);
				})}
			</select>
			<label
				htmlFor={label}
				className={`
          
        `}
			>
				{label}
			</label>
		</div>
	);
};
*/
