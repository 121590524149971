import TrackingSection from "../home/TrackingSection";
import TrackingBoard from "./TrackingBoard";

const Tracking = () => {
	// if (loading) return <div>loading</div>;

	return (
		<div className="relative isolate px-6 py-6 sm:py-6 lg:px-8">
			<TrackingBoard />

			<TrackingSection />
		</div>
	);
};

export default Tracking;
