import React, { useEffect } from "react";
import { getPast } from "../service/PastService";
import { connect } from "react-redux";
import CabinetEmptySpace from "../../components/CabinetEmptySpace";
import CabinetLoading from "../../components/CabinetLoading";
import CabinetPageLayout from "../../components/CabinetPageLayout";
import ResponsiveTableProxy from "../../components/ResponsiveTableProxy";
import Pagination from "../../components/Pagination";

const Past = ({ getPast, past }) => {
	const {
		data,
		current_page,
		first_page_url,
		last_page_url,
		links,
		total,
		to,
		from,
		last_page,
	} = past;

	useEffect(() => {
		document.title = "Past | Delivery Network ";
		getPast(1);
	}, [getPast]);

	return (
		<CabinetPageLayout>
			<CabinetLoading>
				<CabinetEmptySpace data={data}>
					<ResponsiveTableProxy data={data} />
					<Pagination
						{...{
							current_page,
							first_page_url,
							last_page_url,
							last_page,
							links,
							total,
							to,
							from,
							serviceCall: getPast,
						}}
					/>
				</CabinetEmptySpace>
			</CabinetLoading>
		</CabinetPageLayout>
	);
};
const mapStateToProps = (state) => {
	return {
		past: state.cabinet.past.orders,
	};
};

const mapDispatch = (dispatch) => {
	return {
		getPast: () => dispatch(getPast()),
	};
};

export default connect(mapStateToProps, mapDispatch)(Past);
